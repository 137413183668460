import { MoreOutlined } from "@ant-design/icons";
import { Badge, Button, Image, Input, Radio, Space, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { httpClient, ROOT_URL } from "../../Api/httpClient";
import { useAuth } from "../../hooks/useAuth";
import chat from "../../utils/Chat";

import {
  SendOutlined,
  PictureOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useMessage } from "../../hooks/useMessage";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import InfiniteScroll from "react-infinite-scroll-component";

import chatlogo from "../../chatlogo.png";
import Modal from "antd/es/modal/Modal";

var calendar = require("dayjs/plugin/calendar");
dayjs.extend(calendar);

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const RoomCreate = ({ open, onClose, onSelect }) => {
  const { data: userData } = useQuery({
    queryKey: ["users/get_teachers/"],
    queryFn: () => httpClient.get("users/get_teachers/"),
  });

  const [selectedUser, setSelectedUser] = useState();

  return (
    <Modal
      open={open}
      onCancel={() => {
        setSelectedUser("");
        onClose?.();
      }}
      onOk={() => {
        onSelect?.(userData?.data?.find((f) => (f.id = selectedUser)));
        setSelectedUser("");
      }}
      centered
      maskClosable={false}
      okButtonProps={{
        disabled: !selectedUser,
      }}
    >
      <div className="py-8">
        <Radio.Group
          onChange={(e) => {
            setSelectedUser(e.target.value);
          }}
          value={selectedUser}
          className="w-full"
        >
          <Space direction="vertical" className="w-full">
            {userData?.data?.map((t) => {
              return (
                <Radio
                  key={t.id}
                  value={t.id}
                  className="!p-3 bg-black/20 rounded-lg w-full"
                >
                  <div
                    key={t.id}
                    htmlFor={t.id}
                    className="ml-2 flex flex-row gap-2 items-center"
                  >
                    <div className="rounded-full w-9 h-9 bg-red-100 text-black flex items-center justify-center font-bold">
                      {t.first_name[0]}
                    </div>
                    <div className="flex flex-col flex-1">
                      <span>
                        {t.first_name} {t.last_name}
                      </span>
                      <span>{t.email}</span>
                    </div>
                  </div>
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  );
};

const Chat = () => {
  const { currentUser } = useAuth();

  const messagebox = useRef();
  // const [messages, setMessages] = useState([])
  const [text, setText] = useState(null);
  const [meTyping, setMeTyping] = useState(false);
  const [otherTyping, setOtherTyping] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageControl, setMessageControl] = useState({
    next: null,
    previous: null,
  });
  const [rooms, setRooms] = useState([]);
  const [currentTime, setCurrentTime] = useState(null);

  const [imageToUpload, setImageToUpload] = useState(null);
  const [showImagePreview, setShowImagePreview] = useState(null);

  const [roomModelOpen, setRoomModelOpen] = useState(false);
  const { connect, messages: notification } = useMessage();

  const scrollToBottom = () => {
    setTimeout(() => {
      document
        .querySelector(".message-component")
        ?.scrollTo(
          0,
          document.querySelector(".message-component").scrollHeight,
        );
      messagebox.current?.focus();
    }, 100);
  };

  const imageMessageMutation = useMutation({
    mutationFn: (data) => httpClient.post("messages/", data),
  });

  const roomCreateMutation = useMutation({
    mutationFn: (data) => httpClient.post("rooms/", data),
  });

  const { refetch: refetchRoom } = useQuery({
    queryKey: ["rooms/"],
    queryFn: () => httpClient.get("rooms/"),
    onSuccess: (res) => {
      setRooms(res.data);
    },
  });

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000 * 60);
  }, []);

  const { refetch: messagesRefetch, isLoading: messagesLoading } = useQuery({
    queryKey: ["messages/by-room/", chat.getActiveRoom()?.id],
    queryFn: () =>
      httpClient.get(`messages/by-room/${chat.getActiveRoom()?.id}/`),
    enabled: !!selectedChat,
    onSuccess: (res) => {
      setMessages(formatMessages(res.data.results));
      setMessageControl({ next: res.data?.next, previous: res.data?.previous });
      scrollToBottom();
      chat.sendMessage(
        chat.getActiveRoom().id,
        chat.getActiveRoom().users,
        "mark_read",
        true,
      );
      setRooms([
        ...rooms.map((value) => {
          if (value.id == chat.getActiveRoom().id)
            return { ...value, unread_count: 0 };
          return value;
        }),
      ]);
    },
    onSettled: (res) => {},
  });

  const fetchMoreMessages = async () => {
    await httpClient.get(messageControl.next).then((res) => {
      setMessages([...messages, ...formatMessages(res.data.results)]);
      setMessageControl({ next: res.data?.next, previous: res.data?.previous });
    });
  };

  const formatMessages = (array) => {
    if (array.length > 0 && array[0].sender_email == currentUser.email)
      return array;

    let count = 0;
    for (let x = 0; x < array.length; x++) {
      if (
        !(array[x].unread == true && array[x].sender_email != currentUser.email)
      ) {
        break;
      }
      count++;
    }
    if (count > 0) {
      array[count - 1]["show_unread"] = true;
      array[count - 1]["total_unread"] = count;
    }

    return array;
  };

  const onConversationClicked = (event) => {
    setSelectedChat(event.target.value);
    let room = rooms.find((room) => room.id == event.target.value);
    chat.setActiveRoom(room);
    messagesRefetch().then((res) => {});
  };

  const sendMessage = (type) => {
    if (type == "text" && text.trim() != "") {
      let tempID = uuidv4();
      let message = {
        id: tempID,
        sender_email: currentUser.email,
        text: text,
        image: null,
        unread: true,
        created: new Date(),
        room: chat.getActiveRoom().id,
        identifier: tempID,
      };
      setMessages([
        message,
        ...messages.map((value) => {
          return { ...value, show_unread: false };
        }),
      ]);
      chat.sendMessage(
        chat.getActiveRoom().id,
        chat.getActiveRoom().users,
        "message",
        text.trim(),
        tempID,
      );
      scrollToBottom();
      setText("");
    } else if (type == "image") {
      let formData = new FormData();
      formData.append("room", chat.getActiveRoom().id);
      formData.append("image", imageToUpload, imageToUpload.name);
      formData.append("sender", currentUser.id);
      formData.append("unread", true);

      imageMessageMutation.mutate(formData, {
        onSuccess: (res) => {
          chat.sendMessage(
            chat.getActiveRoom().id,
            chat.getActiveRoom().users,
            "image",
            res.data.image,
            res.data.id,
          );
          console.log("here", res.data);
          /* messagesRefetch(); */
        },
        onSettled: () => {
          setShowImagePreview(null);
        },
      });

      scrollToBottom();
    }
  };

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (notification && notification.data) {
      let received = JSON.parse(notification.data);

      if (received.type == "message") {
        console.log("message", received);
        setRooms([
          ...rooms.map((value) => {
            if (
              value.id == received.room &&
              received.room != chat.getActiveRoom()?.id &&
              received.sender != currentUser.email
            )
              return {
                ...value,
                unread_count: received.unread_count,
                last_message: received.message,
              };
            else if (
              value.id == received.room &&
              received.room == chat.getActiveRoom()?.id
            )
              return {
                ...value,
                unread_count: 0,
                last_message: received.message,
              };
            return value;
          }),
        ]);
      }

      if (received.room == chat.getActiveRoom()?.id) {
        switch (received.type) {
          case "typing":
            setOtherTyping(received);
            clearTimeout(othertimeout.current);
            othertimeout.current = setTimeout(() => {
              setOtherTyping(null);
            }, 5000);
            break;
          case "message":
            console.log("here", received);
            setMessages([
              received.message,
              ...messages.filter(
                (message) => message?.identifier != received.identifier,
              ),
            ]);
            chat.sendMessage(
              chat.getActiveRoom().id,
              chat.getActiveRoom().users,
              "mark_read",
              true,
            );
            break;
          case "mark_read":
            setMessages([
              ...messages.map((value) => {
                return { ...value, unread: false };
              }),
            ]);
            break;
        }
      }
    }
  }, [notification]);

  const timeout = useRef();
  const othertimeout = useRef();
  const overalldate = useRef();

  function timeoutFunction() {
    setMeTyping(false);
    // chat.sendMessage(chat.getActiveRoom().id, chat.getActiveRoom().users, "typing", false, currentUser.email);
  }

  function onType() {
    if (meTyping === false) {
      setMeTyping(true);
      // chat.sendMessage(chat.getActiveRoom().id, chat.getActiveRoom().users, "typing", true, currentUser.email)
      timeout.current = setTimeout(timeoutFunction, 5000);
    }
  }

  useEffect(() => () => clearTimeout(timeout.current), []);
  useEffect(() => () => clearTimeout(othertimeout.current), []);
  useEffect(() => () => clearTimeout(overalldate.current), []);

  const handleTypingChange = (e) => {
    setText(e.target.value);
    onType();
  };

  const getHumanReadableTime = (time) => {
    return dayjs(time).calendar();
  };

  const getHumanReadableTimeRoom = (time) => {
    return dayjs(time).calendar(null, {
      sameDay: (now) => {
        var minutes = Math.floor(
          dayjs.duration(dayjs().diff(time)).asMinutes(),
        );
        var hours = Math.floor(dayjs.duration(dayjs().diff(time)).asHours());
        if (minutes == 0) return "now";
        else if (minutes > 0 && minutes < 60) return `${minutes} m`;
        else return `${hours + 1} h`;
      }, // The same day ( Today at 2:30 AM )

      nextDay: "[Tomorrow]", // The next day ( Tomorrow at 2:30 AM )
      nextWeek: "dddd", // The next week ( Sunday at 2:30 AM )
      lastDay: "[Yesterday]", // The day before ( Yesterday at 2:30 AM )
      lastWeek: "dddd", // Last week ( Last Monday at 2:30 AM )
      sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
    });
  };

  const getHumanReadableTimeMessage = (time) => {
    return dayjs(time).calendar(null, {
      sameDay: "[Today]", // The same day ( Today at 2:30 AM )

      nextDay: "[Tomorrow]", // The next day ( Tomorrow at 2:30 AM )
      nextWeek: "dddd", // The next week ( Sunday at 2:30 AM )
      lastDay: "[Yesterday]", // The day before ( Yesterday at 2:30 AM )
      lastWeek: "dddd", // Last week ( Last Monday at 2:30 AM )
      sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
    });
  };

  const onImageSelected = (event) => {
    if (event.target.files.length > 0) {
      setImageToUpload(event.target.files[0]);
      setShowImagePreview(URL.createObjectURL(event.target.files[0]));
    } else {
      setImageToUpload(null);
      setShowImagePreview(null);
    }
  };

  function areElementsOverlapping(element1, element2) {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();

    return (
      rect1.right >= rect2.left &&
      rect1.left <= rect2.right &&
      rect1.bottom >= rect2.bottom &&
      rect1.top <= rect2.bottom + 5
    );
  }

  return (
    <>
      <RoomCreate
        open={roomModelOpen}
        onClose={() => {
          setRoomModelOpen(false);
        }}
        onSelect={(e) => {
          const tag = `${currentUser.id}_${e.id}`;
          const room = rooms.find((f) => f.tag === tag);
          if (!room) {
            roomCreateMutation.mutate(
              {
                tag,
                roomtype: "ONETOONE",
                users: [currentUser.id, e.id],
              },
              {
                onSuccess: () => {
                  refetchRoom().then((e) => {
                    const r = e.data?.data?.find((f) => f.tag === tag);
                    if (r) {
                      setSelectedChat(r.id);
                      chat.setActiveRoom(r);
                      messagesRefetch();
                    }
                  });
                  setRoomModelOpen(false);
                },
              },
            );
          } else {
            setSelectedChat(room.id);
            chat.setActiveRoom(room);
            messagesRefetch();
            setRoomModelOpen(false);
          }
        }}
      />
      <div className="h-full flex flex-row bg-[#242424]">
        <div className="h-full flex flex-col grow-0 shrink basis-72 min-w-[250px] border-solid border-0 border-r border-[#383838] relative">
          {/* bg-[#2E333D] */}
          {rooms?.map((room) => {
            return (
              <label key={room.id}>
                <input
                  checked={selectedChat == room.id}
                  type="radio"
                  hidden
                  className="peer"
                  name="conversation"
                  value={room.id}
                  onChange={onConversationClicked}
                />
                <div className="peer-checked:bg-[#36363b] peer-hover:peer-checked:bg-[#36363b] peer-hover:bg-[#36363b]/50 p-3 flex flex-row cursor-pointer">
                  <div className="relative h-12 w-12 mr-2">
                    <img
                      className="h-full w-full object-cover rounded-2xl"
                      src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                    />
                  </div>
                  <div className="flex-1 flex flex-col justify-between py-1.5 min-w-0">
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-sm">{room.name}</span>
                      <span
                        className="text-xs text-white/60"
                        data={currentTime}
                      >
                        {getHumanReadableTimeRoom(room.last_message?.created)}
                      </span>
                    </div>
                    <div className="flex flex-row items-center text-xs text-white/60">
                      <Badge
                        className="site-badge-count-109"
                        count={room.unread_count}
                        style={{
                          backgroundColor: "#22c55e",
                          marginRight: "5px",
                        }}
                        showZero={false}
                        size={"small"}
                      />
                      <span className="flex-1 min-w-0 truncate">
                        {room.last_message ? (
                          room.last_message.text != "" ? (
                            room.last_message.text
                          ) : (
                            <div>
                              <PictureOutlined /> Photo
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </label>
            );
          })}
          <button
            className="border-0 outline-none text-white cursor-pointer absolute bottom-5 right-5 bg-sky-600 hover:bg-sky-700 w-10 h-10 rounded-xl shadow-3xl flex items-center justify-center text-xl"
            onClick={() => {
              setRoomModelOpen(true);
            }}
          >
            +
          </button>
        </div>
        {!!selectedChat && !!chat.getActiveRoom() ? (
          <Spin
            wrapperClassName="w-full h-full message-container"
            spinning={messagesLoading}
            indicator={<LoadingOutlined />}
          >
            <div className="flex flex-col flex-1 bg-[#131517]">
              <div className="relative flex flex-row justify-between items-center bg-[#242424] px-8 py-3 chat-header">
                <div className="flex flex-row items-center">
                  <div className="relative h-12 w-12 mr-2">
                    <img
                      className="h-full w-full object-cover rounded-2xl"
                      src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                    />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="text-md font-bold mb-1">
                      {chat.getActiveRoom().name}
                    </div>
                    {chat.getActiveRoom().roomtype == "ONETOONE" ? (
                      <div className="text-xs text-white/60">
                        {chat.getActiveRoom().online
                          ? "online"
                          : `last seen ${getHumanReadableTime(chat.getActiveRoom().last_seen)}`}
                      </div>
                    ) : (
                      <div className="text-xs text-white/60">{`${chat.getActiveRoom()?.users?.length} member(s)`}</div>
                    )}
                  </div>
                </div>
                <div>
                  <Button type="text" size="small">
                    <MoreOutlined className="!text-lg !text-white/60" />
                  </Button>
                </div>
                <div
                  id="overall-date"
                  className="bg-[#36363b] px-4 py-2 rounded absolute z-10 bottom-[-34px] left-1/2 -translate-x-1/2 transition opacity-0"
                />
              </div>
              <div
                className="overflow-y-scroll flex flex-col-reverse flex-1 my-0.5 px-8"
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={messages.length}
                  next={fetchMoreMessages}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflowX: "hidden",
                  }} //To put endMessage and loader to the top.
                  className="message-component"
                  inverse={true} //
                  hasMore={messageControl?.next}
                  loader={
                    <div className="flex flex-col justify-center items-center py-3">
                      <LoadingOutlined />
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  onScroll={(e) => {
                    let messageElements =
                      document.querySelectorAll(".message-element");
                    messageElements.forEach((ele) => {
                      // console.log(ele.getBoundingClientRect());
                      // console.log(document.querySelector('.chat-header').getBoundingClientRect());
                      document.querySelector("#overall-date").style.opacity = 1;
                      if (
                        areElementsOverlapping(
                          ele,
                          document.querySelector(".chat-header"),
                        )
                      ) {
                        if (ele?.getAttribute("created")) {
                          console.log(
                            getHumanReadableTimeMessage(
                              ele.getAttribute("created"),
                            ),
                          );
                          document.querySelector("#overall-date").textContent =
                            getHumanReadableTimeMessage(
                              ele.getAttribute("created"),
                            ).toUpperCase();
                          clearTimeout(overalldate.current);
                          overalldate.current = setTimeout(() => {
                            document.querySelector(
                              "#overall-date",
                            ).style.opacity = 0;
                          }, 3000);
                        }
                      }
                    });
                  }}
                >
                  <div>
                    {!!otherTyping && otherTyping.message && (
                      <div className="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    )}
                  </div>
                  {messages?.map((message, index, array) => {
                    let isSameDate = !!array?.[index + 1]
                      ? dayjs(array[index].created).isSame(
                          dayjs(array?.[index + 1]?.created),
                          "day",
                        )
                      : true;

                    let maindom = null;

                    if (message.sender_email == currentUser.email) {
                      maindom = (
                        <div
                          key={message.id}
                          id={message.id}
                          created={message.created}
                          className="flex flex-col my-2 self-end items-end max-w-[70%] message-element"
                        >
                          <div
                            className={`from-me mb-1 ${message.text == "" && "!p-0.5 "} ${message.text.length > 90 || message.text == "" ? "!pb-[25px]" : "!pb-[10px] !pr-[37px]"}`}
                          >
                            <Badge
                              count={
                                <div
                                  className={`text-[10px] !top-auto ${message.text.length > 90 || message.text == "" ? "!bottom-[-25px]" : "!bottom-[-12px]"}`}
                                >
                                  {dayjs(message.created).format("hh:mm")}
                                </div>
                              }
                              offset={[
                                message.text == ""
                                  ? -15
                                  : message.text.length > 90
                                    ? 0
                                    : 20,
                                0,
                              ]}
                            >
                              {message.text != "" ? (
                                <div>{message.text}</div>
                              ) : (
                                <div>
                                  <Image
                                    width={150}
                                    src={`${ROOT_URL}messages/image/${message.id}`}
                                    className="rounded-[10px] w-36"
                                  />
                                </div>
                              )}
                            </Badge>
                          </div>
                          {!message.sender ? (
                            <LoadingOutlined className="!text-[11px] text-white/50" />
                          ) : message.unread ? (
                            <CheckCircleOutlined className="!text-[11px] text-white/50" />
                          ) : (
                            <CheckCircleFilled className="!text-[11px] text-white/50" />
                          )}
                        </div>
                      );
                    } else {
                      maindom = (
                        <div
                          key={message.id}
                          id={message.id}
                          created={message.created}
                          className="flex flex-row my-2 self-start max-w-[70%] message-element"
                        >
                          <div
                            className={`from-them ${message.text == "" && "!p-0.5 "} ${message.text.length > 90 || message.text == "" ? "!pb-[25px]" : "!pb-[10px] !pr-[37px]"}`}
                          >
                            <div>
                              <div
                                className={`text-xs mb-2 text-blue-300 ${message.text == "" && "!px-[10px] !pt-[10px]"}`}
                              >{`${message.sender_fname} ${message.sender_lname}`}</div>
                              <Badge
                                className="!w-full"
                                count={
                                  <div
                                    className={`text-[10px] !top-auto ${message.text.length > 90 || message.text == "" ? "!bottom-[-25px]" : "!bottom-[-12px]"}`}
                                  >
                                    {dayjs(message.created).format("hh:mm")}
                                  </div>
                                }
                                offset={[
                                  message.text == ""
                                    ? -15
                                    : message.text.length > 90
                                      ? 0
                                      : 17,
                                  0,
                                ]}
                              >
                                {message.text != "" ? (
                                  <div>{message.text}</div>
                                ) : (
                                  <div>
                                    <Image
                                      width={150}
                                      src={`${ROOT_URL}messages/image/${message.id}`}
                                      className="rounded-[10px] w-36"
                                    />
                                  </div>
                                )}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );

                      if (message?.show_unread && message?.total_unread > 0) {
                        maindom = [
                          maindom,
                          <div
                            key={`${message.id}-unread`}
                            className="flex items-center justify-center py-4"
                          >
                            <span className="bg-[#3e3e3e] rounded-xl py-2 px-4">{`${message.total_unread} unread messages`}</span>
                          </div>,
                        ];
                      }
                    }

                    let notsamedatedom = "";

                    if (!isSameDate) {
                      notsamedatedom = (
                        <div
                          key={`${message.id}-differentday`}
                          className="text-md rounded self-center bg-[#36363b] px-4 py-2 which-date"
                        >
                          {getHumanReadableTimeMessage(
                            message.created,
                          ).toUpperCase()}
                        </div>
                      );
                      maindom = [maindom, notsamedatedom];
                    }

                    return maindom;
                  })}
                </InfiniteScroll>
              </div>
              <div className="flex flex-row bg-[#242424] px-8 py-4">
                <div className="bg-[#131517] rounded-xl flex flex-row w-full py-1 pr-1">
                  <Input
                    value={text}
                    autoFocus
                    ref={messagebox}
                    placeholder="Type a message"
                    bordered={false}
                    onInput={handleTypingChange}
                    className="flex-1"
                    onPressEnter={(e) => sendMessage("text")}
                  />
                  <Button
                    type="text"
                    shape="circle"
                    icon={<SendOutlined />}
                    onClick={() => sendMessage("text")}
                  />
                </div>
                {/* !bg-[#131517] !rounded-xl */}
              </div>
            </div>
            {!!showImagePreview && (
              <div className="absolute inset-0 top-[72px] bg-[#181818] flex flex-col items-center justify-center">
                <img src={showImagePreview} className="w-36 mb-5" />
                <Button
                  loading={imageMessageMutation.isLoading}
                  type="primary"
                  size="large"
                  icon={<SendOutlined />}
                  onClick={() => sendMessage("image")}
                >
                  Send
                </Button>
              </div>
            )}
          </Spin>
        ) : (
          <div className="flex flex-col flex-1 pl-14">
            <div className="flex flex-col h-full w-full items-center justify-center">
              <img className="h-36 w-36" src={chatlogo} />
              <span className="mt-7 text-white/60">
                Say more with our chat messaging app.
              </span>
              <span className="mt-2 text-white/60">
                Stay connected with your classmates and instructors on chat.
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Chat;
