import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate, Outlet, matchRoutes, matchPath } from "react-router-dom"
import {
    ConfigProvider,
    Layout,
    Breadcrumb,
    theme,
    Menu,
    Button,
    Avatar,
    Dropdown,
    Space
} from 'antd';
import { UserOutlined, DashboardOutlined, BookOutlined, LogoutOutlined, MessageOutlined } from '@ant-design/icons';

import { NavLink } from "react-router-dom";

import routes from "../../utils/routes.config";
import { useAuth } from "../../hooks/useAuth";
import chat from "../../utils/Chat";
import { useMessage } from "../../hooks/useMessage";
import { notification } from 'antd';

const { Content, Sider, Header, Footer } = Layout

const messageNotificationKey = "message_notification_key"

const HomePage = ({ props }) => {
    const currentPath = useLocation();
    const [layoutMarginLeft, setLayoutMarginLeft] = useState("200px")
    const [selectedKeys, setSelectedKeys] = useState("/" + currentPath.pathname.split("/")?.[1]);
    const [api, contextHolder] = notification.useNotification();
    
    const location = useLocation();

    const { currentUser, logout } = useAuth()

    const { connect, disconnect, messages } = useMessage()

    useEffect(() => {
        if (currentUser) {
            connect()
        }
    }, [])

    useEffect(() => {

        if(messages && messages?.data){
            let receivedmessage = JSON.parse(messages.data)
            if (receivedmessage && receivedmessage?.type != "typing" && receivedmessage?.type != 'mark_read' && receivedmessage.sender != currentUser.email && location.pathname != "/chat") {
                api.open({
                    key: messageNotificationKey,
                    message: receivedmessage?.sender_name,
                    description: receivedmessage?.message.text,
                    type: "info",
                    icon: <MessageOutlined className="text-blue-500"/>,
                    
                })
            }
        }
    }, [messages])

    if (!currentUser) {
        return <Navigate to="login" replace />
    }

    const handleMenuClick = (event) => {
        setSelectedKeys(event.key)
    }
    return (
        <>
            {contextHolder}
            <Layout className="h-full overflow-hidden">
                <Header
                    className="header flex flex-row justify-between items-center !px-4"
                    style={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        background: '#111111',
                        marginLeft: '200px',
                        paddingInline: '0px',
                        lineHeight: 'unset'
                    }}>
                    <span className="font-bold text-md">Welcome back, {`${currentUser.first_name} ${currentUser.last_name}`}</span>
                    <div className="flex flex-row items-center justify-end h-full cursor-pointer">
                        <Dropdown menu={{
                            items: [
                                {
                                    label: 'Profile',
                                    key: '1',
                                    icon: <UserOutlined />,
                                },
                                {
                                    label: 'Logout',
                                    key: '2',
                                    icon: <LogoutOutlined />,
                                },

                            ],
                            onClick: (e) => {
                                if (e.key == "2") {
                                    chat.disconnect()
                                    chat.setActiveRoom(null)
                                    logout()
                                }
                            }
                        }}
                            trigger={"click"}
                        >
                            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                        </Dropdown>
                    </div>

                </Header>
                <Layout>
                    <Sider
                        width={200}
                        breakpoint="md"
                        collapsedWidth="0"
                        trigger={null}
                        collapsible
                        theme="dark"

                        onCollapse={(collapsed, type) => {
                            if (collapsed) {
                                setLayoutMarginLeft("0px")
                            } else {
                                setLayoutMarginLeft("200px")
                            }
                        }}
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                            background: '#111111'
                        }}>
                        <div style={{ height: "64px" }}></div>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['/']}
                            onSelect={handleMenuClick}
                            selectedKeys={[selectedKeys]}
                        >
                            {routes({ user: currentUser }).map((item) => {
                                if (!item.allowedRoles.includes(currentUser?.usertype))
                                    return
                                return (
                                    <Menu.Item key={item.path}>
                                        <NavLink to={item.path}>
                                            {item.icon}
                                            <span>{item.label}</span>
                                        </NavLink>
                                    </Menu.Item>
                                )
                            })}
                        </Menu>
                    </Sider>
                    <Layout
                        style={{
                            marginLeft: layoutMarginLeft,
                            color: 'white',
                            position: "relative"
                        }}>
                        <Outlet />
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default HomePage;