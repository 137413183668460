import ReconnectingWebSocket from "reconnecting-websocket";

class Chat {
  #connection = null;
  #activeroom = null;

  constructor() {
    if (Chat.instance) {
      return Chat.instance;
    }

    Chat.instance = this;
  }

  connect() {
    var userid = JSON.parse(localStorage.getItem("user")).id;
    console.log("connect");

    switch (this.#connection?.readystate) {
      case WebSocket.OPEN:
        break;

      case WebSocket.CONNECTING:
        break;

      case WebSocket.CLOSED:
        this.#init(userid);
        break;

      case WebSocket.CLOSING:
        this.#init(userid);
        break;

      default:
        break;
    }

    if (this.#connection == null) {
      this.#init(userid);
    }

    console.log(this.#connection);
    return this.#connection;
  }

  #init(userid) {
    this.#connection = new ReconnectingWebSocket(
      process.env.NODE_ENV === "development"
        ? `ws://localhost:8000/chats/${userid}/`
        : `chats/${userid}/`,
    );
    this.#connection.addEventListener("close", () => {
      console.warn("websocket closed");
    });
  }

  disconnect() {
    if (this.#connection) {
      this.getInstance().close();
    }
    this.#connection = null;
    console.log("close", this.#connection);
  }

  getInstance() {
    return this.#connection;
  }

  sendMessage(room, users, type, message, identifier) {
    this.getInstance().send(
      JSON.stringify({ room, users, type, message, identifier }),
    );
  }

  onreceive() {
    return this.getInstance().onmessage;
  }

  setActiveRoom(room) {
    this.#activeroom = room;
  }

  getActiveRoom() {
    return this.#activeroom;
  }
}

const chat = new Chat();

export default chat;
