import {
  UserOutlined,
  MessageOutlined,
  DashboardOutlined,
  BookOutlined,
  CompassOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Calendar from "../Pages/Calendar/Calendar";
import Chat from "../Pages/Chat/chat";
import Class from "../Pages/Class/Class";
import Course from "../Pages/Course/Course";
import CourseStudent from "../Pages/Course/Course.Student";
import CourseOutlet from "../Pages/Course/CourseOutlet";
import Library from "../Pages/Library/Library";
import LibraryOutlet from "../Pages/Library/LibraryOutlet";
import Note from "../Pages/Notes/Notes";
import Quiz from "../Pages/Quiz/Quiz";
import Quizes from "../Pages/Quiz/Quizes";
import QuizOutlet from "../Pages/Quiz/QuizOutlet";
import QuizPlay from "../Pages/Quiz/QuizPlay";
import UserDetail from "../Pages/UserDetail";
import UserOutlet from "../Pages/UserOutlet";
import Users from "../Pages/Users";

const routes = ({ user }) => {
  let route = [
    {
      path: "/",
      label: "Dashboard",
      icon: <DashboardOutlined />,
      index: true,
      allowedRoles: ["ADMIN", "TEACHER", "STUDENT"],
      element: <UserOutlet />,
    },
    {
      path: "/chat",
      label: "Chat",
      icon: <MessageOutlined />,
      index: true,
      allowedRoles: ["ADMIN", "TEACHER", "STUDENT"],
      element: <Chat />,
    },
    {
      path: "/mycourse",
      label: "My Courses",
      icon: <BookOutlined />,
      index: false,
      allowedRoles: ["STUDENT"],
      element: <CourseOutlet />,
      children: [
        {
          path: "",
          index: true,
          element: <CourseStudent />,
        },
        {
          path: ":id",
          index: false,
          element: <Class />,
        },
      ],
    },
    {
      path: "/course",
      label: user?.usertype == "STUDENT" ? "Browse Courses" : "Courses",
      icon: <CompassOutlined />,
      index: false,
      allowedRoles: ["ADMIN", "TEACHER", "STUDENT"],
      element: <CourseOutlet />,
      children: [
        {
          path: "",
          index: true,
          element: <Course />,
        },
        {
          path: ":id",
          index: false,
          element: <Class />,
        },
      ],
    },
    {
      path: "/quiz",
      label: "Quizzes",
      icon: <CompassOutlined />,
      index: false,
      allowedRoles: ["ADMIN", "TEACHER", "STUDENT"],
      element: <QuizOutlet />,
      children: [
        {
          path: "",
          index: true,
          element: <Quizes />,
        },
        {
          path: ":id",
          index: false,
          element: <Quiz />,
        },
        {
          path: "play/:id",
          index: false,
          element: <QuizPlay />,
        },
      ],
    },
    {
      path: "/library",
      label: "Library",
      icon: <BookOutlined />,
      allowedRoles: ["ADMIN", "TEACHER"],
      index: false,
      element: <LibraryOutlet />,
      children: [
        {
          path: "",
          index: true,
          element: <Library />,
        },
        {
          path: ":id",
          index: false,
          element: <Note />,
        },
      ],
    },
    {
      path: "/calendar",
      label: "Calendar",
      icon: <CalendarOutlined />,
      allowedRoles: ["ADMIN", "STUDENT"],
      index: true,
      element: <Calendar />,
    },
    {
      path: "/users",
      label: "Users",
      icon: <UserOutlined />,
      index: false,
      allowedRoles: ["ADMIN", "TEACHER"],
      element: <UserOutlet />,
      children: [
        {
          path: "",
          index: true,
          element: <Users />,
        },
        {
          path: ":id",
          index: false,
          element: <UserDetail />,
        },
      ],
    },
  ];

  return route;
};

export default routes;
