import React, { useState, useEffect } from "react";
import { Button, Card, DatePicker, Form, Input, List, Radio, Select, Spin, Switch, Modal as AntModal } from "antd"
import { httpClient, ROOT_URL } from "../../Api/httpClient";
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import { useMutation, useQuery } from "react-query";
import Modal from "../../Components/Modal";
import { useForm } from "antd/es/form/Form";
import { CirclePicker } from "react-color";

const { confirm } = AntModal;

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const Quizes = () => {

    const navigate = useNavigate()
    const [form] = useForm();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [actionMode, setActionMode] = useState("ADD")
    const [updateData, setUpdateData] = useState(null)

    const quizzes = useQuery({ queryKey: ["quizzes/"], queryFn: () => httpClient.get("quizzes/") })
    const courses = useQuery({ queryKey: ["courses/"], queryFn: () => httpClient.get("courses/") }, { enabled: false })

    const createQuiz = useMutation({
        mutationFn: (data) => httpClient.post("quizzes/", data),
        onSuccess: () => {
            setIsModalOpen(false);
            quizzes.refetch()
            form.resetFields()
        }
    })

    const updateQuiz = useMutation({
        mutationFn: (data) => httpClient.patch(`quizzes/${data.id}/`, data.values),
        onSuccess: () => {
            setIsModalOpen(false);
            quizzes.refetch()
            form.resetFields()
        }
    })

    const deleteQuiz = useMutation({
        mutationFn: (id) => httpClient.delete(`quizzes/${id}/`),
        onSuccess: () => {
            quizzes.refetch()
        }
    })

    const handleEdit = (record) => {
        setActionMode('UPDATE')
        setUpdateData(record)
        form.resetFields()
        form.setFieldsValue({
            name: record.name,
            active: record.active,
            courses: record.courses,
            color: record.color,
        })
        form.validateFields()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                // console.log(values);
                if (actionMode == "ADD") {
                    createQuiz.mutate({ ...values, color: values.color.hex })
                } else {
                    if (updateData && updateData.id)
                        updateQuiz.mutate({ id: updateData.id, values: { ...values, color: values.color.hex } })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }


    
    const handleDelete = (record) => {
        confirm({
            title: 'Delete class',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this class?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteQuiz.mutate(record.id)
            },
            onCancel() {
            },
        })
    }

    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">Quizzes</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <Button type='default' size="small" className="!flex items-center justify-center" icon={<PlusOutlined />} onClick={() => {
                        setActionMode("ADD");
                        courses.refetch();
                        form.resetFields();
                        setIsModalOpen(true);
                    }} >Add</Button>
                </div>
            </>
        } body={
            <>
                <List
                    className="!p-4 "
                    grid={{
                        gutter: 20,
                        xs: 1,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    loading={quizzes.isLoading}
                    dataSource={quizzes.data?.data}
                    renderItem={(item) => (
                        <List.Item>
                            <Card hoverable
                                cover={
                                    <div style={{ background: item.color }} className={`transition rounded p-2 !flex items-center justify-center min-h-[100px] cursor-pointer duration-250 ease-in hover:ease-out hover:shadow-[${item.color}]/30 hover:shadow-xl`}>
                                        {/* <img src={`${ROOT_URL}/static${item.avatar}`} height={96} width={96} /> */}
                                        <span className="text-lg font-bold">{item.name}</span>
                                    </div>
                                }
                                actions={[
                                    <EditOutlined key="edit" onClick={(e) => {
                                        e.stopPropagation()
                                        handleEdit(item)
                                    }} />,
                                    <DeleteOutlined key="delete" onClick={(e) => {
                                        e.stopPropagation()
                                        handleDelete(item)
                                    }} />,
                                ]}
                                bodyStyle={{ padding: "0px" }}
                            onClick={() => { navigate("" + item.id) }}
                            >
                            </Card>
                        </List.Item>
                    )}
                />
                <Modal
                    open={isModalOpen}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    confirmLoading={createQuiz.isLoading || courses.isLoading || updateQuiz.isLoading}
                    centered
                    title={actionMode == "ADD" ? "Add quiz" : "Update quiz"}
                    maskClosable={false}
                >
                    <Spin spinning={createQuiz.isLoading || courses.isLoading || updateQuiz.isLoading} tip="Processing" size="large">
                        <Form form={form} name="user" layout="horizontal" initialValues={{ color: "#039be5", active:true }}>
                            <FormItem name='name' rules={[{ required: true }]}
                                label={`Name`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter quiz name' />
                            </FormItem>
                            <FormItem name='courses' rules={[{ required: true, }]}
                                label={`Courses`} hasFeedback {...formItemLayout}>
                                <Select
                                    mode="multiple"
                                    placeholder="Select Courses"
                                    options={courses.data?.data?.map((course) => {
                                        return { label: course.name, value: course.id }
                                    })}
                                />
                            </FormItem>
                            <FormItem name="color" label={`Quiz color`} valuePropName="color" rules={[{ required: true }]} {...formItemLayout}>
                                <CirclePicker circleSize={18} circleSpacing={12} width={"100%"} colors={["#d50000", "#e67c73", "#f4511e", "#f6bf26", "#33b679", "#0b8043", "#039be5", "#3f51b5", "#7986cb", "#8e24aa", "#616161"]} />
                            </FormItem>
                            <FormItem name='active' rules={[{ required: true, }]}
                                label={`Active`} valuePropName="checked" hasFeedback {...formItemLayout}>
                                <Switch />
                            </FormItem>
                        </Form>
                    </Spin>
                </Modal>
            </>
        } />
    )
}

export default Quizes
