import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import { ClockCircleOutlined } from '@ant-design/icons';
import rrulePlugin from '@fullcalendar/rrule'

import { v4 as uuidv4 } from 'uuid';

import { CirclePicker } from "react-color"
import "./Calendar.css"
import { createRef, useEffect, useState } from 'react';
import Modal from '../../Components/Modal';
import { Form, Input, Spin, DatePicker, Space, Checkbox, Select, TimePicker, InputNumber, Radio, Button, message, Modal as AntModal } from 'antd';

import * as dayjs from 'dayjs'
import { useForm, useWatch } from 'antd/es/form/Form';
import { datetime, Frequency, RRule, RRuleSet, rrulestr, Weekday } from 'rrule'
import { httpClient } from '../../Api/httpClient';
const FormItem = Form.Item;

const { warn } = AntModal
const { RangePicker } = DatePicker

const formatAllDay = "ddd, MMM DD, YYYY"
const formatNotAllDay = "ddd, MMM DD, YYYY hh:mm a"

const FREQUENCY = [
    { value: RRule.DAILY, label: "Day" },
    { value: RRule.WEEKLY, label: "Week" },
    { value: RRule.MONTHLY, label: "Month" },
    { value: RRule.YEARLY, label: "Year" },
]

const REPEAT_MODE = [
    { value: "DONOTREPEAT", label: "Do not repeat" },
    { value: "CUSTOM", label: "Custom" },
]

const WEEKDAY = [
    { value: RRule.SU.weekday, label: "Sun" },
    { value: RRule.MO.weekday, label: "Mon" },
    { value: RRule.TU.weekday, label: "Tue" },
    { value: RRule.WE.weekday, label: "Wed" },
    { value: RRule.TH.weekday, label: "Thu" },
    { value: RRule.FR.weekday, label: "Fri" },
    { value: RRule.SA.weekday, label: "Sat" },
]

const fetchEvents = (info, successCallback, failureCallback) => {
    httpClient.get("events/")
        .then((e) => successCallback(e.data))
        .catch((error) => failureCallback(error))
}

const Calendar = () => {
    const [courses, setCourses] = useState([])
    const [grades, setGrades] = useState([])
    const [gradesLoading, setGradesLoading] = useState(false)
    const [coursesLoading, setCoursesLoading] = useState(false)
    const [openTimeDialog, setOpenTimeDialog] = useState(false)
    const [openCustomOccurance, setOpenCustomOccurance] = useState(false)
    const [repeatModes, setRepeatModes] = useState(REPEAT_MODE)
    const [events, setEvents] = useState([])

    const [weekdays, setWeekdays] = useState([])
    const [eventTitle, SetEventTitle] = useState("")
    const [endRadio, setEndRadio] = useState(1)
    const [currentEvent, setCurrentEvent] = useState(null)
    const [checkBoxAllDay, setCheckBoxAllDay] = useState(false)
    const [currentRepeatMode, setCurrentRepeatMode] = useState("DONOTREPEAT")
    const [currentRule, setCurrentRule] = useState("")

    const calendarRef = createRef()

    const [formRef] = useForm()
    const [occeranceFormef] = useForm()

    const repeatWatch = useWatch("mode", occeranceFormef)
    const colorWatch = useWatch("color", formRef)

    // useEffect(() => {
    //     httpClient.get("events/")
    //         .then((e) => {
    //             setEvents(e.data)
    //         })
    // }, [])
    const getCourses = () => {
        setCoursesLoading(true)
        httpClient.get("courses/")
            .then((e) => {
                setCourses(e.data)
                setCoursesLoading(false)
            })
    }

    const getGrades = (course) => {
        setGradesLoading(true)
        httpClient.get("grades/grades_by_course/" + course + "/")
            .then((e) => {
                setGrades(e.data)
                setGradesLoading(false)
                formRef.setFieldValue("grade", e.data?.[0]?.id)
            })
    }

    const handleDialogClose = (e) => {
        setOpenTimeDialog(false)
        formRef.resetFields()
        setCheckBoxAllDay(false)
        calendarRef.current.getApi().unselect()
        setCurrentRepeatMode("DONOTREPEAT")
        setRepeatModes(REPEAT_MODE)
        occeranceFormef.resetFields()
        setEndRadio(1)
    }

    const handleDialogOk = (e) => {
        formRef.validateFields()
            .then((values) => {

                const { title, startdate, enddate, color, time, repeatmode, link, course } = values
                var rule = null
                var ruleOptions = null
                if (repeatmode && repeatmode == "RULE" && repeatModes.length == 3) {

                    ruleOptions = repeatModes[1].rule.options;

                    ruleOptions = {
                        interval: ruleOptions?.interval,
                        freq: ruleOptions.freq,
                        dtstart: checkBoxAllDay ? datetime(dayjs(startdate).year(), dayjs(startdate).month() + 1, dayjs(startdate).date()) : dayjs(startdate).set("hour", time[0].hour()).set("minute", time[0].minute()).toDate(),
                        byweekday: ruleOptions.byweekday,
                        count: ruleOptions.count,
                        until: ruleOptions.until
                    }

                    rule = new RRule(ruleOptions)
                }

                let postData = null
                let eventData = null

                if (checkBoxAllDay) {
                    eventData = {
                        title: title,
                        start: startdate.toDate(),
                        end: dayjs(enddate).add(1, 'day').toDate(),
                        color: typeof (colorWatch) == 'object' ? colorWatch.hex : colorWatch,
                        allDay: checkBoxAllDay,
                    }

                    if (rule) {
                        eventData['rrule'] = rule.toString()
                    }



                    console.log(eventData);
                } else {
                    // let startDate = dayjs(startdate).
                    let totalMinutes = dayjs(time[1]).diff(time[0], 'minute')
                    const hours = Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;

                    let startDate = dayjs(startdate).set("hour", time[0].hour()).set("minute", time[0].minute())
                    let endDate = dayjs(startdate).set("hour", time[1].hour()).set("minute", time[1].minute())

                    eventData = {
                        title: title,
                        start: startDate.toDate(),
                        end: endDate.toDate(),
                        color: typeof (colorWatch) == 'object' ? colorWatch.hex : colorWatch,
                        allDay: checkBoxAllDay,
                        duration: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`
                    }

                    if (rule) {
                        eventData['rrule'] = rule.toString()
                    }

                }


                postData = { ...eventData, link: link, course: course }

                httpClient.post("events/", postData)
                    .then((e) => {
                        console.log(e);
                        calendarRef.current.getApi().unselect()
                        calendarRef.current.getApi().refetchEvents()

                        setOpenTimeDialog(false)

                        setRepeatModes(REPEAT_MODE)
                    })
            })
    }

    const handleColorChange = (e) => {
        setMirrorColor(e.hex)
    }

    const onEndRadioChanged = (e) => {
        console.log(e);
        setEndRadio(e.target.value)
    }

    const setMirrorColor = (color) => {
        let mirrorElement = document.querySelector(".fc-event-mirror");
        console.log(mirrorElement);
        if (mirrorElement) {
            mirrorElement.style.background = color
            mirrorElement.style.borderColor = color
        }
    }


    const onEndDateChanged = (e) => {
        console.log(e.add(1, "day"));
        calendarRef.current.getApi().select({ start: currentEvent.start, end: e.add(1, "day").toDate(), allDay: checkBoxAllDay })
    }

    const onStartDateChanged = (e) => {

        if (!checkBoxAllDay) {
            let endDate = dayjs(currentEvent.end).set('year', e.year()).set('month', e.month()).set('date', e.date())
            let startDate = dayjs(currentEvent.start).set('year', e.year()).set('month', e.month()).set('date', e.date())
            console.log(e.toDate(), endDate.toDate());
            calendarRef.current.getApi().select({ start: startDate.toDate(), end: endDate.toDate(), allDay: checkBoxAllDay })
        } else {
            calendarRef.current.getApi().select({ start: e.toDate(), end: currentEvent.end, allDay: checkBoxAllDay })
        }

    }

    const onTimeChanged = (e) => {
        console.log(e);
        console.log(currentEvent.start);
        let startDate = dayjs(currentEvent.start).set("hour", e[0].hour()).set("minute", e[0].minute())
        let endDate = dayjs(currentEvent.start).set("hour", e[1].hour()).set("minute", e[1].minute())

        console.log(startDate, endDate);
        calendarRef.current.getApi().select({ start: startDate.toDate(), end: endDate.toDate(), allDay: checkBoxAllDay })
    }

    const onFrequencyChange = (e) => {
        if (e == Frequency.WEEKLY) {
            occeranceFormef.setFieldValue("weekdays", [WEEKDAY[dayjs(currentEvent.start).day()].value])
            let until = dayjs(formRef.getFieldValue("startdate")).add(1, "month")
            occeranceFormef.setFieldValue("until", until)
            setWeekdays([WEEKDAY[dayjs(currentEvent.start).day()].value])
        }
        else if (e == Frequency.MONTHLY) {
            let until = dayjs(formRef.getFieldValue("startdate")).add(4, "month")
            occeranceFormef.setFieldValue("until", until)
        }
        else if (e == Frequency.YEARLY) {
            let until = dayjs(formRef.getFieldValue("startdate")).add(4, "year")
            occeranceFormef.setFieldValue("until", until)
        }
        else if (e == Frequency.DAILY) {
            let until = dayjs(formRef.getFieldValue("startdate")).add(1, "month")
            occeranceFormef.setFieldValue("until", until)
        }
    }

    const onRepeatModeChanged = (e) => {
        console.log(e);
        setCurrentRepeatMode(e)
        if (e == "CUSTOM") {
            if (repeatModes.length == 3 && repeatModes[1].value == "RULE") {
                let rule = repeatModes[1].rule.options;
                console.log(rule);
                let values = {
                    interval: rule?.interval,
                    frequency: rule.freq,
                    weekdays: rule.byweekday
                }

                if (rule.count) {
                    values['occurance'] = rule.count
                    setEndRadio(1)
                }

                if (rule.until) {
                    values['until'] = dayjs(rule.until)
                    setEndRadio(2)
                }
                occeranceFormef.setFieldsValue(values)
            }
            else {
                let until = dayjs(formRef.getFieldValue("startdate")).add(1, "month")
                let values = {
                    interval: 1,
                    freq: Frequency.DAILY,
                    until: until
                }
                occeranceFormef.setFieldsValue(values)
                setEndRadio(1)
            }
            setOpenCustomOccurance(true)
        }

    }

    const onCustomOccuranceOK = (e) => {
        occeranceFormef.validateFields()
            .then((values) => {

                const { interval, weekdays, mode, until, occurance } = values

                var rule = null;


                var options = {
                    interval: interval,
                }

                switch (mode) {
                    case Frequency.WEEKLY:
                        {
                            options["byweekday"] = weekdays.map((day) => new Weekday(day))
                            break;
                        }
                    case Frequency.MONTHLY:
                        {

                            break;
                        }
                    case Frequency.YEARLY:
                        {
                            break;
                        }
                    case Frequency.DAILY:
                        {
                            break;
                        }
                    default:
                        break;
                }

                if (endRadio == 1) {
                    options['until'] = datetime(dayjs(until).year(), dayjs(until).month() + 1, dayjs(until).date())
                } else {
                    options['count'] = occurance
                }
                options["freq"] = mode
                rule = new RRule(options)

                setRepeatModes([REPEAT_MODE[0], { value: "RULE", label: rule.toText(), rule: rule }, REPEAT_MODE[1]])
                formRef.setFieldValue("repeatmode", "RULE")
                setOpenCustomOccurance(false)
                occeranceFormef.resetFields()
                setCurrentRepeatMode("RULE")
                setEndRadio(1)
            })
    }

    const onCustomOccuranceCancel = (e) => {
        if (repeatModes.length == 3 && repeatModes[1].value == "RULE") {
            formRef.setFieldValue("repeatmode", "RULE")
        } else {
            formRef.setFieldValue("repeatmode", "DONOTREPEAT")
            setCurrentRepeatMode("DONOTREPEAT")
        }
        setOpenCustomOccurance(false)
        occeranceFormef.resetFields()
        setEndRadio(1)
    }

    function disabledTime(current) {
        return {
            disabledHours: () => {
                const hours = [];
                for (let i = 0; i < dayjs(formRef.getFieldValue('eventstartdate')).hour(); i++) {
                    hours.push(i);
                }
                return hours;
            },
        };
    }

    const revertEvent = (e, type) => {
        let totalMinutes = dayjs(e.oldEvent.end).diff(e.oldEvent.start, 'minutes')
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        let duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`

        message.open({
            key: e.oldEvent.id,
            type: "loading",
            duration: 0,
            content: "Reverting..."
        })

        let postData = {
            start: e.oldEvent.start,
            end: e.oldEvent.end,
        }

        if (!e.oldEvent.allDay && type == "resize") {
            postData['duration'] = duration
        }


        console.log(e.oldEvent.id);
        httpClient.patch("events/" + e.oldEvent.id + "/", postData)
            .then((res) => {
                console.log(res);
                e.revert();
                message.open({
                    key: e.oldEvent.id,
                    type: 'success',
                    content: <span>Revert successful.</span>
                })
            })
    }


    const onEventResizeOrDragged = (e, type) => {
        let totalMinutes = dayjs(e.event.end).diff(e.event.start, 'minutes')
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        let duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`


        message.open({
            key: e.event.id,
            type: "loading",
            duration: 0,
            content: "Saving..."
        })
        console.log(e.event.id);

        let ruleOptions = null
        if (e.event._def.recurringDef?.typeData?.rruleSet?.toString()) {
            ruleOptions = rrulestr(e.event._def.recurringDef?.typeData?.rruleSet?.toString())
        }
        let rrule = null
        console.log(ruleOptions);
        if (ruleOptions) {
            let options = {
                dtstart: e.event.start,
                until: ruleOptions.options.until,
                interval: ruleOptions.options.interval,
                count: ruleOptions.options.count,
                byweekday: ruleOptions.options.byweekday,
                freq: ruleOptions.options.freq
            }
            rrule = new RRule(options)
            console.log(rrule.toString());
        }

        let postData = {
            start: e.event.start,
            end: e.event.end,
        }



        if (!e.event.allDay && type == "resize") {
            postData['duration'] = duration
        }

        if (rrule && type == "drag") {
            postData['rrule'] = rrule.toString()
        }



        httpClient.patch("events/" + e.event.id + "/", postData)
            .then((res) => {
                console.log(res);

                message.open({
                    key: e.event.id,
                    type: 'success',
                    content: <><span>Saved</span><Button className='ml-4' size='small' onClick={() => {
                        revertEvent(e, type)

                    }}>Undo</Button></>
                })
            })
    }


    return <div className='p-4 h-full bg-[#111] !text-white'>
        <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin]}
            headerToolbar={{
                start: "today prev next",
                end: "dayGridMonth timeGridWeek timeGridDay",
            }}
            selectAllow={(e) => {
                if (e.start.getDate() != e.end.getDate() && !e.allDay) {
                    return false;
                }
                return true;
            }}

            eventAllow={(dropInfo, dragEvent) => {
                if (dragEvent._def.recurringDef?.typeData?.rruleSet && dragEvent.start.getDate() != dropInfo.start.getDate() || dragEvent.start.getMonth() != dropInfo.start.getMonth()) {
                    message.open({
                        key: dragEvent.id,
                        type: "warning",
                        content: "Cannot move recurring event to another day. Please change manually. "
                    })
                    return false
                }
                return dropInfo.allDay == dragEvent.allDay
            }}

            // nextDayThreshold="23:59:59"
            initialView="timeGridWeek"
            allDayText='All day'
            editable
            selectable
            views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
            weekends={true}
            events={fetchEvents}
            height="100%"
            nowIndicator={true}
            dayMaxEventRows={true}
            selectMirror={true}
            unselectAuto={true}
            unselectCancel=".ant-modal, .ant-picker-dropdown, .ant-select-dropdown .rc-virtual-list"
            themeSystem="custom"
            lazyFetching={true}
            eventColor='#039be5'
            eventDidMount={(e) => {
                // console.log(e);
                if (e.isMirror && !e.isDragging && !e.isResizing) {
                    e.el.style.background = colorWatch?.hex ? colorWatch?.hex : "#039be5"
                    e.el.style.borderColor = colorWatch?.hex ? colorWatch?.hex : "#039be5"
                }
            }}
            eventsSet={(e) => {
                // console.log(e);
            }}
            select={(e) => {

                setCurrentEvent(e)
                if (!openTimeDialog) {
                    getCourses()
                    setCheckBoxAllDay(e.allDay)
                    formRef.setFieldsValue({ "title": "", "startdate": dayjs(e.start), "enddate": dayjs(e.end).subtract(1, "day"), "time": [dayjs(e.start), dayjs(e.end)], color: "#039be5", repeatmode: "DONOTREPEAT" })
                    setOpenTimeDialog(true)
                }

            }}

            eventResize={(e) => {
                onEventResizeOrDragged(e, "resize")
            }}

            eventDrop={(e) => {
                onEventResizeOrDragged(e, "drag")
            }}

        />

        <Modal
            title="Add event"
            open={openTimeDialog}
            onCancel={handleDialogClose}
            onOk={handleDialogOk}
            // confirmLoading={courseLoading}
            centered

            maskClosable={true}
        >
            <Spin spinning={false} tip="Updating" size="large">
                <Form form={formRef} layout="vertical" preserve={false} initialValues={{ "mode": FREQUENCY[0].value, "interval": 1, "occurance": 4 }}>

                    <div className='flex flex-col w-full'>
                        <FormItem name='title' rules={[{ required: true }]} className="!mb-1.5">
                            <Input required placeholder='Add title' size='medium' className='w-full' />
                        </FormItem>

                        <div className='mb-1'>Starts on</div>
                        <div className='flex flex-row w-full'>
                            <FormItem name='startdate' rules={[{ required: true }]} className="!mb-1.5 !mr-1">
                                <DatePicker onChange={onStartDateChanged} suffixIcon={null} allowClear={false} placeholder='Start date' picker='date' showTime={false} format={formatAllDay} />
                            </FormItem>
                            {
                                !checkBoxAllDay && (<FormItem name='time' rules={[{ required: true }]} className="!mb-1.5">
                                    <TimePicker.RangePicker onChange={onTimeChanged} suffixIcon={null} allowClear={false} format="hh:mm a" />
                                </FormItem>)
                            }
                            {
                                (checkBoxAllDay && currentRepeatMode == "DONOTREPEAT") && (
                                    <FormItem name='enddate' rules={[{ required: true }]} className="!mb-1.5">
                                        <DatePicker onChange={onEndDateChanged} suffixIcon={null} allowClear={false} placeholder='End date' picker='date' showTime={false} format={formatAllDay} />
                                    </FormItem>
                                )
                            }
                        </div>
                    </div>
                    <FormItem name="repeatmode" label={`Repeat`} className="!mb-1.5" rules={[{ required: true }]}>
                        <Select
                            className='!w-full'
                            placeholder="Select repeat type"
                            allowClear={false}
                            options={repeatModes}
                            onChange={onRepeatModeChanged}
                        />
                    </FormItem>

                    <FormItem name="color" label={`Event color`} valuePropName="color" className="!mb-1.5" rules={[{ required: true }]}>
                        <CirclePicker circleSize={18} circleSpacing={12} width={"100%"} onChangeComplete={handleColorChange} colors={["#d50000", "#e67c73", "#f4511e", "#f6bf26", "#33b679", "#0b8043", "#039be5", "#3f51b5", "#7986cb", "#8e24aa", "#616161"]} />
                    </FormItem>

                    <FormItem >
                        <Checkbox checked={checkBoxAllDay} onChange={(e) => { setCheckBoxAllDay(e.target.checked); setOpenCustomOccurance(true); }}>All day</Checkbox>
                    </FormItem>

                    <FormItem name="course" label={`Course`} style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }} rules={[{ required: true }]}>
                        <Select
                            className='!w-full'
                            placeholder="Choose course"
                            allowClear={false}
                            options={courses.map((course) => { return { value: course.id, label: course.name } })}
                            loading={coursesLoading}

                        />
                    </FormItem>
                    <FormItem name='link' label="Meeting link">
                        <Input required placeholder='Meeting link' size='medium' className='w-full' />
                    </FormItem>
                </Form>

            </Spin>
        </Modal>
        <Modal
            title="Custom occurance"
            open={openCustomOccurance}
            onCancel={onCustomOccuranceCancel}
            onOk={onCustomOccuranceOK}
            // confirmLoading={courseLoading}
            okText="Done"
            centered

            maskClosable={false}
        >
            <Form form={occeranceFormef} layout="vertical" preserve={false} initialValues={{ "mode": Frequency.DAILY, "interval": 1, "occurance": 4 }}>
                <FormItem label={`Repeat every`} className="!mb-1.5">
                    <Input.Group compact className='!flex flex-row w-full'>
                        <FormItem noStyle name="interval">
                            <InputNumber min={1} className='!w-20' />
                        </FormItem>

                        <FormItem noStyle name="mode">
                            <Select
                                className='!w-full'
                                placeholder="Select repeat type"
                                options={FREQUENCY}
                                allowClear={false}
                                onChange={onFrequencyChange}
                            />
                        </FormItem>
                    </Input.Group>
                </FormItem>

                {(repeatWatch == Frequency.WEEKLY) && (
                    <FormItem name="weekdays" label={`Repeat on`} className="!mb-1.5">
                        <Select
                            mode="multiple"
                            allowClear={false}
                            placeholder="Please select week day"
                            onChange={(e) => {
                                setWeekdays(e)
                            }}

                            options={WEEKDAY.map((day) => {
                                if (weekdays?.length == 1 && weekdays[0] == day.value) {
                                    return { value: day.value, label: day.label, disabled: true }
                                }
                                return { value: day.value, label: day.label, disabled: false }

                            })}
                        />
                    </FormItem>
                )}

                <div className='flex flex-col'>
                    <span>Ends</span>
                    <Radio.Group onChange={onEndRadioChanged} value={endRadio}>
                        <Space direction="vertical">
                            <Radio value={1}>
                                <div className='flex flex-row items-center'>
                                    <span className='w-10'>On</span>
                                    <FormItem name="until" rules={[{ required: endRadio == 1 }]} className="!mb-0">
                                        <DatePicker disabled={endRadio != 1} suffixIcon={null} allowClear={false} placeholder='End date' picker='date' showTime={false} format={formatAllDay} />
                                    </FormItem>
                                </div>
                            </Radio>
                            <Radio value={2}>
                                <div className='flex flex-row items-center'>
                                    <span className='w-10'>After</span>
                                    <FormItem name="occurance" className="!mb-0">
                                        <InputNumber disabled={endRadio != 2} min={1} className='!w-20' />
                                    </FormItem>
                                    <span className='ml-3'>Occurance</span>
                                </div>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Form>
        </Modal>
    </div>
}

export default Calendar;