import React, { useState, useEffect } from "react";
import { List, Card, Space, Select, Breadcrumb, Button, Table, Layout, Form, Input, InputNumber, DatePicker, Radio, Spin, message, Modal as AntModal } from "antd"
import { PlusOutlined, ExclamationCircleFilled, ArrowLeftOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Modal from "../../Components/Modal";
import * as dayjs from 'dayjs'
import { useForm } from "antd/es/form/Form";
import { httpClient } from "../../Api/httpClient";

import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
// import Card from "../../Components/Card";
// import CardCourseAdmin from "../../Components/Card.Course.Admin";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery } from "react-query";

import Chat from "../../utils/Chat"


const { Meta } = Card
const FormItem = Form.Item;
const { confirm } = AntModal;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const Course = () => {

    const navigate = useNavigate()

    const { currentUser } = useAuth()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [actionMode, setActionMode] = useState('ADD')
    const [updateData, setUpdateData] = useState(null)


    const courses = useQuery({ queryKey:["courses/"], queryFn: () => httpClient.get("courses/") })


    const createCourse = useMutation({
        mutationFn: (data) => httpClient.post("courses/", data),
        onSuccess: () => {
            setIsModalOpen(false);
            courses.refetch()
            form.resetFields()
        }
    })

    const updateCourse = useMutation({
        mutationFn: (data) => httpClient.patch(`courses/${data.id}/`, data.data),
        onSuccess: () => {
            setIsModalOpen(false);
            courses.refetch()
            form.resetFields()
        }
    })

    const deleteCourse = useMutation({
        mutationFn: (id) => httpClient.delete(`courses/${id}/`),
        onSuccess: () => {
            courses.refetch()
        }
    })


    const enrollCourse = useMutation({
        mutationFn: (id) => httpClient.post("users/enroll_course/", { course: id }),
        onSuccess: () => {
            courses.refetch()
        }
    })

    const [form] = useForm();


    const handleEdit = (record) => {
        setActionMode('UPDATE')
        setUpdateData(record)
        form.resetFields()
        form.setFieldsValue({
            name: record.name,
            discount: record.discount,
            price: record.price,
            startdate: dayjs(record.startdate, 'YYYY-MM-DD').isValid() ? dayjs(record.startdate, 'YYYY-MM-DD') : "",
        })
        form.validateFields()
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                if (actionMode == "ADD") {
                    createCourse.mutate({ ...values, startdate: values['startdate'].format('YYYY-MM-DD') })
                } else {
                    updateCourse.mutate({ id: updateData.id, data: { ...values, startdate: values['startdate'].format('YYYY-MM-DD') } })
                }
            })
            .catch((err) => {
            })
    }

    const handleDelete = (record) => {
        confirm({
            title: 'Delete class',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this class?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteCourse.mutate(record.id)
            },
            onCancel() {
            },
        })
    }

    return (
        <>
            <Container header={
                <>
                    <div className="flex flex-col">
                        <span className="text-2xl font-bold">Course</span>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        {currentUser.usertype == "ADMIN" && <Button className="!flex items-center justify-center" size="small" icon={<PlusOutlined />} onClick={() => { setActionMode('ADD'); setIsModalOpen(true); }} >Add</Button>}
                    </div>
                </>
            } body={
                <>
                    <List
                        grid={{
                            gutter: 20,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }}
                        className="!p-4 "
                        loading={courses.isLoading}
                        dataSource={courses.data?.data}
                        renderItem={(item) => (
                            <List.Item>
                                <Card
                                    onClick={() => {
                                        if ((currentUser && currentUser.usertype == "STUDENT" && item.enrolled) || !(currentUser && currentUser.usertype == "STUDENT")) {
                                            navigate("" + item.id)
                                        }
                                    }}
                                    hoverable
                                    cover={
                                        <img
                                            alt="example"
                                            src="https://cdn.mos.cms.futurecdn.net/HFUAjfbamNhbM8dsNSQW3D.jpg"
                                        />
                                    }
                                    actions={ currentUser && currentUser.usertype == "STUDENT" ? [
                                    <div 
                                    className={`mx-3 py-1 rounded text-white ${!item.enrolled?'bg-sky-600 hover:bg-sky-800':'bg-emerald-600 hover:bg-emerald-800'}`}
                                    onClick={()=>{
                                        if(!item.enrolled){
                                            enrollCourse.mutate(item.id)
                                        }else{
                                            navigate("" + item.id)
                                        }
                                    }}
                                    >{!item.enrolled ? "Enroll now" : "View course"}</div>
                                ] : [
                                        <EditOutlined key="edit" onClick={(e)=>{
                                            e.stopPropagation()
                                            handleEdit(item)
                                        }}/>,
                                        <DeleteOutlined key="delete" onClick={(e)=>{
                                            e.stopPropagation()
                                            handleDelete(item)
                                        }}/>,
                                    ]}
                                >
                                    <Meta
                                        title={item.name}
                                        description={
                                            <>
                                                <span className="text-xs text-white/50">{"Starting from " + dayjs(item.startdate).format('ddd, MMM D, YYYY')}</span>
                                                {!(currentUser && currentUser.usertype == "STUDENT" && item.enrolled) && <div className="my-2">
                                                    <span className={"text-xs mr-2 " + (item.discount > 0 ? "line-through" : "")}>NPR. {item.price}/-</span>
                                                    {item.discount > 0 && <span className="font-bold text-xs text-sky-500">NPR. {item.discount}/-</span>}
                                                </div>}

                                            </>
                                        }
                                    />
                                </Card>
                            </List.Item>
                        )}
                    />
                </>

            }></Container>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                confirmLoading={createCourse.isLoading || updateCourse.isLoading}
                centered
                maskClosable={false}
            >
                <Spin spinning={createCourse.isLoading || updateCourse.isLoading} tip="Updating" size="large">
                    <Form form={form} name="user" layout="horizontal">
                        <FormItem name='name' rules={[{ required: true }]}
                            label={`Course name`} hasFeedback {...formItemLayout}>
                            <Input placeholder='Enter course name' />
                        </FormItem>
                        <FormItem name='price' rules={[{ required: true }]}
                            label={`Course price`} hasFeedback {...formItemLayout}>
                            <InputNumber className="!w-full" placeholder='Enter course price' />
                        </FormItem>
                        <FormItem name='discount' rules={[{ required: true }]}
                            label={`Course discount`} hasFeedback {...formItemLayout}>
                            <InputNumber className="!w-full" placeholder='Enter course discount' />
                        </FormItem>
                        <FormItem name='startdate' label={`Start date`} hasFeedback {...formItemLayout} rules={[{ required: true }]}>
                            <DatePicker format='YYYY-MM-DD' style={{ width: '100%' }} />
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default Course
