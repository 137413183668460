import React, { useState, useEffect, createRef } from "react";
import { Space, Card, Tabs, Select, Dropdown, Button, Table, Layout, Form, Input, Upload, DatePicker, Radio, Spin, message, Modal as AntModal, Tag } from "antd"
import { PlusOutlined, PlayCircleFilled, ExclamationCircleFilled, ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import Modal from "../../Components/Modal";
import * as dayjs from 'dayjs'
import { useForm } from "antd/es/form/Form";
import { httpClient, ROOT_URL } from "../../Api/httpClient";

import 'video-react/dist/video-react.css';
import { BigPlayButton, LoadingSpinner, Player } from 'video-react';
import ReactPlayer from "react-player"
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Container";
import { useMutation, useQuery } from "react-query";
import { RRule } from "rrule";
import { useAuth } from "../../hooks/useAuth";

const { Content } = Layout
const FormItem = Form.Item;
const { confirm } = AntModal;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const assignmentFormItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
}

const sort = (a, b) => {
    return dayjs(a.start) - dayjs(b.start)
}

const getEvent = (event) => {

    var isToday = null;
    var isTomorrow = null;
    var isNow = null;
    var endsAt = null;
    var on = null;
    var todayAt = null;
    var passed = false;
    var isRecurringRule = false;
    var nextDate = null


    let endTime = dayjs(event.end)
    let startTime = dayjs(event.start)
    let todayEnd = dayjs().set('hour', endTime.hour()).set('minute', endTime.minute()).set('second', 0).toDate()
    let todayStart = dayjs().set('hour', startTime.hour()).set('minute', startTime.minute()).set('second', 0).subtract(1, 'second').toDate()


    if (event.rrule) {
        let rrule = RRule.fromString(event.rrule);
        let nextOccurance = rrule.after(new Date(), true)
        isToday = dayjs(nextOccurance).isSame(dayjs(), 'day')
        isTomorrow = dayjs(nextOccurance).isSame(dayjs().add(1, 'day'), 'day')
        isRecurringRule = true

        if (dayjs().diff(rrule.after(todayStart, true)) > 0 && dayjs(todayEnd).diff() > 0) {
            isNow = rrule.after(todayStart, true)
            if (isNow) {
                endsAt = endTime.format("hh:mm a")
            }
        }

        if (isToday) {
            todayAt = dayjs(nextOccurance).format("hh:mm a")
        }
        else if (isTomorrow) {
            on = dayjs(nextOccurance).format("h:mm a")
        } else {
            nextDate = nextOccurance
            on = dayjs(nextOccurance).format("dddd, MMMM D, YYYY h:mm a")
        }

    } else {

        isTomorrow = dayjs(event.start).isSame(dayjs().add(1, 'day'), 'day')

        console.log("isNOw", dayjs().diff(event.start), dayjs(event.end).diff(), event.title);
        if (dayjs().diff(event.start) > 0 && dayjs(event.end).diff() > 0) {
            isNow = todayStart
            if (isNow) {
                endsAt = endTime.format("hh:mm a")
            }
        }

        if (!isNow) {
            isToday = dayjs(event.start).isSame(dayjs(), 'day') && dayjs(event.start).diff() > 0
        }

        if (isToday) {
            todayAt = dayjs(event.start).format("hh:mm a")
        } else if (isTomorrow) {
            on = dayjs(event.start).format("h:mm a")
        }
        else {
            on = dayjs(event.start).format("dddd, MMMM D, YYYY h:mm a")
        }

        if (dayjs().diff(event.end) > 0) {
            passed = true
        }
    }

    let eventText = on;

    if (isNow) {
        eventText = "Ongoing now and will end at " + endsAt;
    }

    if (isToday) {
        eventText = "Today at " + todayAt;
    }

    if (isTomorrow) {
        eventText = "Tomorrow at " + on;
    }

    return {
        isNow,
        isToday,
        isTomorrow,
        endsAt,
        on,
        todayAt,
        eventText,
        passed,
        isRecurringRule,
        nextDate,
        ...event
    }
}


const filterEvents = (e) => {
    let data = e.data.map((data) => getEvent(data))
    let isNow = data.filter((event) => event.isNow)
    let today = data.filter((event) => event.isToday && !event.passed)

    today.sort(sort)

    let tomorrow = data.filter((event) => event.isTomorrow && !event.passed)

    tomorrow.sort(sort)

    let remaining = data.filter((event) => !event.isTomorrow && !event.isNow && !event.isToday && !event.passed)
    remaining.sort((a, b) => {
        let astart = a.start;
        let bstart = b.start;

        if (a.isRecurringRule && a.nextDate)
            astart = a.nextDate
        if (b.isRecurringRule && b.nextDate)
            bstart = b.nextDate

        return astart - bstart
    })

    console.log([...isNow, ...today, ...tomorrow, ...remaining]);

    return [...isNow, ...today, ...tomorrow, ...remaining]
}


const Class = () => {

    let navigate = useNavigate()
    const { currentUser } = useAuth()

    const [courseForm] = useForm();
    const [assignmentForm] = useForm();
    const [assignmentSubmitForm] = useForm();

    const { id } = useParams()


    const [isVideoModelOpen, setVideoModelOpen] = useState(false)
    const [currentLecture, setCurrentLecture] = useState({})

    const [isCourseModalOpen, setIsCourseModalOpen] = useState(false)
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false)
    const [isAssignmentSubmitModalOpen, setIsAssignmentSubmitModalOpen] = useState(false)
    const [assignmentUploadFile, setAssignmentUploadFile] = useState([])

    const [noteType, setNoteType] = useState('document')

    const [eventsData, setEventsData] = useState([])

    const [submitAssignmentId, setSubmitAssignmentId] = useState(null)

    const playerRef = createRef()

    const course = useQuery({ queryKey: [`courses/${id}/`], queryFn: () => httpClient.get(`courses/${id}/`) })
    const notes_by_course = useQuery({
        queryKey: [`notes/notes_by_course/${id}/`], queryFn: () => httpClient.get(`notes/notes_by_course/${id}/`),
        select: (e) => {
            return { ...e, data: { videos: e.data.filter((note) => note.library.type == "video"), documents: e.data.filter((note) => note.library.type == "document") } }
        },
    })
    const notes_without_course = useQuery({
        queryKey: [`notes/notes_without_course/${id}/`], queryFn: () => httpClient.get(`notes/notes_without_course/${id}/`),
        select: (e) => {
            return { ...e, data: { videos: e.data.filter((note) => note.library.type == "video"), documents: e.data.filter((note) => note.library.type == "document") } }
        },
    })
    const assignments = useQuery({ queryKey: [`assignments/${!(currentUser && currentUser.usertype == "STUDENT") ? 'assignments_by_course' : 'assignments_by_submission'}/${id}/`], queryFn: () => httpClient.get(`assignments/${!(currentUser && currentUser.usertype == "STUDENT") ? 'assignments_by_course' : 'assignments_by_submission'}/${id}/`) })
    const events = useQuery({
        queryKey: ["events"],
        queryFn: () => httpClient.get(`events/events_by_courses/${id}/`),
        onSuccess: (e) => {
            setEventsData(filterEvents(e))
        }
    })


    const addNote = useMutation({
        mutationFn: (data) => httpClient.post("courses/add_notes/" + id + "/", data),
        onSuccess: () => {
            setIsCourseModalOpen(false)
            notes_by_course.refetch()
            notes_without_course.refetch()
        }
    })


    const deleteNote = useMutation({
        mutationFn: (noteid) => httpClient.delete(`courses/remove_notes/${id}/${noteid}`),
        onSuccess: () => {
            notes_by_course.refetch()
            notes_without_course.refetch()
        }
    })


    const deleteAssignment = useMutation({
        mutationFn: (assignmentid) => httpClient.delete(`assignments/${assignmentid}/`),
        onSuccess: () => {
            assignments.refetch()
        }
    })

    const createAssignment = useMutation({
        mutationFn: (data) => httpClient.post("assignments/", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }),
        onSuccess: () => {
            setIsAssignmentModalOpen(false)
            assignments.refetch()
        }
    })


    const submitAssignment = useMutation({
        mutationFn: (data) => httpClient.post("submitassignment/", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }),
        onSuccess: () => {
            setIsAssignmentSubmitModalOpen(false)
            assignments.refetch()
        }
    })

    const columns = [
        {
            title: 'Classes',
            width: 150,
            dataIndex: 'title',
            render: (title, record) => (
                <>
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-col">
                            <div className="font-bold">{title}</div>
                            <div className="flex flex-row items-center">
                                {record.isNow && <div className="w-2 h-2 rounded-full bg-green-600 mr-2 animate-pulse"></div>}
                                <div className="text-white/50">{record.eventText}</div>
                            </div>
                        </div>
                        <div>{record.isNow ? <Button type="default" href={record.link} target="_blank" className="!bg-green-600 hover:!border-white">Join</Button> : ""}</div>
                    </div>
                </>
            )
        }
    ];

    const columnsNotes = [
        {
            title: 'Note name',
            width: 120,
            dataIndex: 'name',
            render: (classname) => <a>{classname}</a>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 80,
            render: (_, record) => {
                return <Space align='middle'>
                    <Button type="link" size="small" onClick={() => downloadNotes(record)}>
                        Download
                    </Button>
                    {!(currentUser && currentUser.usertype == "STUDENT") && <Button type="link" size="small" danger onClick={() => handleDelete(record)}>
                        Delete
                    </Button>}
                </Space>
            }
        }
    ];

    const columnsVideos = [
        {
            title: 'Name',
            width: 120,
            dataIndex: 'name',
            render: (classname, record) => {
                return <>
                    {
                        <div className="flex items-center cursor-pointer" onClick={() => {
                            setCurrentLecture(record)
                            setVideoModelOpen(true)
                            playerRef.current?.load()
                        }}>
                            <div className="flex items-center justify-center relative">
                                <img src={`${ROOT_URL}notes/thumbnail/${record.id}/`} className="h-16 mr-2 rounded" />
                                <PlayCircleFilled className="absolute" style={{ fontSize: '24px' }} />
                            </div>
                            <a>{classname}</a>
                        </div>
                    }
                </>
            }
        },
        ... !(currentUser && currentUser.usertype == "STUDENT") ? [{
            title: 'Action',
            dataIndex: 'action',
            width: 80,
            fixed: 'right',
            render: (_, record) => {
                return <Space align='middle'>
                    <Button type="link" size="small" onClick={() => downloadNotes(record)}>
                        Download
                    </Button>
                    <Button type="link" size="small" danger onClick={() => handleDelete(record)}>
                        Delete
                    </Button>
                </Space>
            }
        }] : []
    ];

    const columnAssignments = [
        {
            title: 'Assignment name',
            width: 120,
            dataIndex: 'name',
            render: (classname) => <a>{classname}</a>
        },
        {
            title: 'Start date',
            width: 120,
            dataIndex: 'startdate',
            render: (classname) => <a>{classname}</a>
        },
        {
            title: 'End date',
            width: 120,
            dataIndex: 'enddate',
            render: (classname) => <a>{classname}</a>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 80,
            render: (_, record) => {
                return <Space align='middle'>
                    {!(currentUser && currentUser.usertype == "STUDENT") ?
                        <>
                            <Button type="link" size="small" onClick={() => downloadAssignments(record)}>
                                Download
                            </Button>
                            <Button type="link" size="small" danger onClick={() => handleDeleteAssignment(record)}>
                                Delete
                            </Button>
                        </> : !record.submitted ? <Button type="link" size="small" onClick={() => {
                            assignmentSubmitForm.resetFields()
                            setSubmitAssignmentId(record.id)
                            setIsAssignmentSubmitModalOpen(true)
                        }}>
                            Submit
                        </Button> : <Tag color="green">Submitted</Tag>}
                </Space>
            }
        }
    ];


    const tabItems = [
        {
            label: "Classes",
            key: "1",
            children: (
                <Table
                    loading={events.isLoading}
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={eventsData}
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 250 }} />
            )
        },
        {
            label: "Notes",
            key: "2",
            children: (
                <Table
                    loading={notes_by_course.isLoading}
                    rowKey={(record) => record.id}
                    columns={columnsNotes}
                    dataSource={notes_by_course.data?.data.documents}
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 250 }} />
            )
        },
        {
            label: "Videos",
            key: "3",
            children: (
                <Table
                    loading={notes_by_course.isLoading}
                    rowKey={(record) => record.id}
                    columns={columnsVideos}
                    dataSource={notes_by_course.data?.data.videos}
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 250 }} />
            )
        },
        {
            label: "Assignments",
            key: "4",
            children: (
                <Table
                    loading={assignments.isLoading}
                    rowKey={(record) => record.id}
                    columns={columnAssignments}
                    dataSource={assignments.data?.data}
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 250 }} />
            )
        }
    ]

    const handleDelete = (record) => {
        confirm({
            title: 'Delete',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                // console.log(record);
                deleteNote.mutate(record.id)
            },
            onCancel() {
            },
        })
    }


    const handleDeleteAssignment = (record) => {
        confirm({
            title: 'Delete',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteAssignment.mutate(record.id)
            },
            onCancel() {
            },
        })
    }


    const handleCourseCancel = () => {
        setIsCourseModalOpen(false)
        courseForm.resetFields()
    }

    const handleCourseOk = () => {
        courseForm.validateFields()
            .then((values) => {
                addNote.mutate(values)
            })
    }

    const handleMenuClick = (e) => {
        console.log(e);
        if (e.key == "1") {
            navigate("/calendar")
        }
        else if (e.key == "2") {
            setNoteType('document')
            courseForm.resetFields()
            setIsCourseModalOpen(true)
        }
        else if (e.key == "3") {
            setNoteType('video')
            courseForm.resetFields()
            setIsCourseModalOpen(true)
        }
        else if (e.key == "4") {
            assignmentForm.resetFields()
            setAssignmentUploadFile([])
            setIsAssignmentModalOpen(true)
        }
    }


    const handleAssignmentOk = () => {
        assignmentForm.validateFields()
            .then((values) => {
                let formData = new FormData();
                formData.append('name', values.name);
                formData.append('course', id);
                formData.append('startdate', values['startdate'].format('YYYY-MM-DD'));
                formData.append('enddate', values['enddate'].format('YYYY-MM-DD'));
                formData.append('file', values.file.originFileObj, values.file.originFileObj.name);

                createAssignment.mutate(formData)
            })
    }

    const handleAssignmentCancel = () => {
        setIsAssignmentModalOpen(false)
        assignmentForm.resetFields()
    }

    const handleAssignmentSubmitOk = () => {
        assignmentSubmitForm.validateFields()
            .then((values) => {
                if (!submitAssignmentId)
                    return
                let formData = new FormData();
                formData.append('assignment', submitAssignmentId);
                formData.append('file', values.file.originFileObj, values.file.originFileObj.name);
                submitAssignment.mutate(formData)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleAssignmentSubmitCancel = () => {
        setIsAssignmentSubmitModalOpen(false)
        assignmentSubmitForm.resetFields()
    }
    const props = {
        onRemove: (file) => {
            setAssignmentUploadFile([])
        },
        beforeUpload: (file) => {
            setAssignmentUploadFile([file])
            return false;
        },
    };

    const normFile = (e) => {
        console.log(e);
        return e?.fileList?.[0]
    }



    const closeVideo = () => {
        setVideoModelOpen(false)
        playerRef.current?.pause()
    }


    const downloadNotes = (record) => {
        const el = document.createElement("a");
        el.setAttribute("href", `${ROOT_URL}notes/lecture/${record.id}/`);
        el.setAttribute(
            "download",
            `${ROOT_URL}notes/lecture/${record.id}/`
        );
        el.click();
    }

    const downloadAssignments = (record) => {
        const el = document.createElement("a");
        el.setAttribute("href", `${ROOT_URL}assignments/download_assignment/${record.id}/`);
        el.setAttribute(
            "download",
            `${ROOT_URL}assignments/download_assignment/${record.id}/`
        );
        el.click();
    }

    return (
        <Container
            header={
                <>
                    <div className="flex flex-col">
                        <span className="text-2xl font-bold">{course.data?.data.name ? course.data.data.name : "Course"}</span>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        {!(currentUser && currentUser.usertype == "STUDENT") && <Dropdown menu={{
                            items: [
                                {
                                    label: 'Add class',
                                    key: '1',
                                },
                                {
                                    label: 'Add notes',
                                    key: '2',
                                },
                                {
                                    label: 'Add videos',
                                    key: '3',
                                },
                                {
                                    label: 'Add assignment',
                                    key: '4',
                                }
                            ], onClick: handleMenuClick
                        }}
                            trigger={['click']}
                            disabled={events.isLoading || notes_by_course.isLoading || notes_without_course.isLoading || assignments.isLoading}
                        >
                            <Button type="default" size="small">
                                <Space>
                                    <PlusOutlined />
                                    Add
                                </Space>
                            </Button>
                        </Dropdown>}
                        {/* <Button type='text' className="!flex items-center justify-center" icon={<PlusOutlined />} onClick={() => { setActionMode('ADD'); setIsModalOpen(true); }} >Add</Button> */}
                        <Button type='default' size="small" className="!flex items-center justify-center ml-1" icon={<ArrowLeftOutlined />} onClick={() => { navigate("../") }}>Back</Button>
                    </div>
                </>
            }
            body={
                <>
                    <Tabs
                        defaultActiveKey="1"
                        items={tabItems}
                        className="!p-4 !pt-0"
                    />

                    <Modal
                        open={isCourseModalOpen}
                        onCancel={handleCourseCancel}
                        onOk={handleCourseOk}
                        confirmLoading={addNote.isLoading}
                        centered
                        maskClosable={false}
                        title={`Add ${noteType}`}
                    >
                        <Spin spinning={addNote.isLoading} tip="Processing..." size="large">
                            <Form form={courseForm} name="notes" layout="horizontal">
                                <FormItem name='notes' rules={[{ required: true }]}
                                    label={`${noteType.charAt(0).toUpperCase()}${noteType.substring(1,noteType.length).toLowerCase() }`} hasFeedback {...formItemLayout}>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={`Select ${noteType}`}
                                        options={notes_without_course.data?.data?.[`${noteType}s`]?.map((course) => {
                                            return { label: course.name, value: course.id }
                                        })}
                                    />
                                </FormItem>
                            </Form>
                        </Spin>
                    </Modal>

                    <Modal
                        open={isAssignmentModalOpen}
                        onCancel={handleAssignmentCancel}
                        onOk={handleAssignmentOk}
                        confirmLoading={createAssignment.isLoading}
                        centered
                        maskClosable={false}
                    >
                        <Spin spinning={createAssignment.isLoading} tip="Processing..." size="large">
                            <Form form={assignmentForm} name="assignment" layout="horizontal">
                                <FormItem name='file' rules={[{ required: true }]}
                                    label={`Select assignment`} hasFeedback {...assignmentFormItemLayout} getValueFromEvent={normFile}>
                                    <Upload accept="application/pdf" multiple={false} fileList={assignmentUploadFile} {...props} listType="picture">
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                </FormItem>
                                <FormItem name='name' rules={[{ required: true }]}
                                    label={`Assignment name`} hasFeedback {...assignmentFormItemLayout}>
                                    <Input placeholder='Enter name' />
                                </FormItem>
                                <FormItem name='startdate' label={`Start date`} hasFeedback {...assignmentFormItemLayout} rules={[{ required: true }]}>
                                    <DatePicker format='MM/DD/YYYY' style={{ width: '100%' }} />
                                </FormItem>
                                <FormItem name='enddate' label={`End date`} hasFeedback {...assignmentFormItemLayout} rules={[{ required: true }]}>
                                    <DatePicker format='MM/DD/YYYY' style={{ width: '100%' }} />
                                </FormItem>
                            </Form>
                        </Spin>
                    </Modal>


                    <Modal
                        open={isVideoModelOpen}
                        onCancel={closeVideo}
                        centered
                        maskClosable={false}
                        footer={null}
                        title={currentLecture.name}
                        maskStyle={{ backdropFilter: "blur(5px)", backgroundColor: "rgba(0,0,0,0.25)" }}
                        className="video-modal"
                        destroyOnClose={true}
                    >
                        <div onContextMenu={(e) => { e.preventDefault() }}>
                            <Player ref={playerRef} autoPlay poster={`${ROOT_URL}notes/thumbnail/${currentLecture.id}/`} >
                                <LoadingSpinner />
                                <BigPlayButton position="center" />
                                <source src={`${ROOT_URL}notes/lecture/${currentLecture.id}/`} />
                            </Player >
                        </div>
                    </Modal>

                    <Modal
                        open={isAssignmentSubmitModalOpen}
                        onCancel={handleAssignmentSubmitCancel}
                        onOk={handleAssignmentSubmitOk}
                        confirmLoading={submitAssignment.isLoading}
                        centered
                        maskClosable={false}
                        title="Submit assignment"
                    >
                        <Spin spinning={submitAssignment.isLoading} tip="Uploading" size="large">
                            <Form form={assignmentSubmitForm} name="assignmentsubmit" layout="horizontal" preserve={false}>
                                <FormItem name='file' rules={[{ required: true }]}
                                    label={`Select assignment`} hasFeedback {...assignmentFormItemLayout} getValueFromEvent={normFile}>
                                    <Upload accept="application/pdf" multiple={false} fileList={assignmentUploadFile} {...props} listType="picture">
                                        <Button icon={<UploadOutlined />}>Select assignment file</Button>
                                    </Upload>
                                </FormItem>
                            </Form>
                        </Spin>
                    </Modal>
                </>
            }
        />
    )
}

export default Class
