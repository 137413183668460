import { Layout } from "antd";
const { Content } = Layout

const Container = ({ header, body }) => {
    return (
        <div className="flex flex-col absolute inset-0">
            <div className="flex flex-row justify-between items-center bg-black/40 backdrop-blur-md absolute inset-0 bottom-auto z-10 px-4 py-2">
                {header}
            </div>
            <Content className="overflow-auto overflow-x-hidden pt-[48px]">
                {body}
            </Content>
        </div>
    )
}

export default Container