import React, { useState, useEffect, useRef } from "react";
import { Button, Checkbox, Card, DatePicker, Form, Input, List, Radio, Select, Spin, Switch, Modal as AntModal, Table } from "antd"
import { httpClient, ROOT_URL } from "../../Api/httpClient";
import { PlusOutlined, CheckOutlined, CloseOutlined, EditFilled, ExclamationCircleFilled, DeleteFilled, ArrowLeftOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Container";
import { useMutation, useQuery } from "react-query";
import Modal from "../../Components/Modal";
import { useForm } from "antd/es/form/Form";
import { CirclePicker } from "react-color";

const { confirm, error, info } = AntModal;

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const QuizPlay = () => {

    const navigate = useNavigate()
    const [form] = useForm();
    const testForm = useRef()

    const { id } = useParams()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [actionMode, setActionMode] = useState("ADD")
    const [updateData, setUpdateData] = useState(null)
    const [options, setOptions] = useState(["", "", "", ""])
    const [userAnswer, setUserAnswer] = useState({})
    const [correctAnswer, setCorrectAnswer] = useState([])
    const [isAnswered, setIsAnswered] = useState(false)

    const quizquestions = useQuery({ queryKey: [`quizquestions/by_quiz/${id}/`], queryFn: () => httpClient.get(`quizquestions/by_quiz/${id}/`) })
    const quiz = useQuery({ queryKey: [`quizzes/${id}/`], queryFn: () => httpClient.get(`quizzes/${id}/`) })

    const createQuizQuestion = useMutation({
        mutationFn: (data) => httpClient.post("quizquestions/", data),
        onSuccess: () => {
            setIsModalOpen(false);
            quizquestions.refetch()
            form.resetFields()
        }
    })

    const updateQuizQuestion = useMutation({
        mutationFn: (data) => httpClient.patch(`quizquestions/${data.id}/`, data.values),
        onSuccess: () => {
            setIsModalOpen(false);
            quizquestions.refetch()
            form.resetFields()
        }
    })

    const deleteQuizQuestion = useMutation({
        mutationFn: (id) => httpClient.delete(`quizquestions/${id}/`),
        onSuccess: () => {
            quizquestions.refetch()
        }
    })


    const createQuizAnswer = useMutation({
        mutationFn: (data) => httpClient.post("quizanswers/", data),
        onSuccess: () => {
        }
    })


    const columns = [
        {
            title: 'Question',
            // width: 150,
            dataIndex: 'question',
            render: (question, record) => {
                return <div>
                    <div className="flex flex-row items-center justify-between">
                        <span className="font-bold">{question}</span>
                        <div className="flex flex-row items-center">
                            <Button type="text" icon={<EditFilled className="text-xs" />} size="small" onClick={() => { handleEdit(record) }} />
                            <Button type="text" icon={<DeleteFilled className="text-xs" />} size="small" onClick={() => { handleDelete(record) }} />
                        </div>
                    </div>
                </div>
            }
        }
    ];

    const handleEdit = (record) => {
        setActionMode('UPDATE')
        setUpdateData(record)
        setOptions(Object.values(record.options))
        form.resetFields()

        let answers = {}

        if (record?.answers?.answers) {
            answers = record.answers.answers.reduce((acc, el) => {
                acc[el] = true
                return acc
            }, {})

        }

        console.log(record.options);
        form.setFieldsValue({
            question: record.question,
            answers: answers,
            options: record.options,
        })
        form.validateFields()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                let answers = Object.entries(values.answers).reduce((acc, ele) => {
                    const [key, value] = ele
                    if (value) {
                        acc.push(key)
                    }
                    return acc
                }, [])
                if (answers.length) {
                    if (actionMode == "ADD") {
                        createQuizQuestion.mutate({ quiz: [id], ...values, answers: { answers: answers } })
                    } else {
                        updateQuizQuestion.mutate({ id: updateData.id, values: { quiz: [id], ...values, answers: { answers: answers } } })
                    }
                } else {
                    error({
                        content: "Please check correct answers."
                    })
                }


            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }



    const handleDelete = (record) => {
        confirm({
            title: 'Delete class',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this class?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteQuizQuestion.mutate(record.id)
            },
            onCancel() {
            },
        })
    }


    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">{`Quiz${quiz.data?.data?.name ? ' - ' + quiz.data?.data?.name : ""}`}</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <span className="mr-2 font-bold">{`${!quizquestions?.isLoading ? "Total questions: " + quizquestions.data?.data.length : ''}`}</span>
                    <span className="font-bold">{`${isAnswered ? ' Correct answers: ' + correctAnswer.length : ''}`}</span>
                </div>
            </>
        } body={
            <>
                <div className="flex flex-col m-2 rounded !p-4 !pt-3 bg-[#111111]">
                    {/* <div className="pl-5 h-14 font-bold bg-[#1d1d1d] flex flex-row items-center" style={{ borderBottom: "1px solid #303030" }}><span>Total questions - {quizquestions.data?.data?.length}</span></div> */}
                    <Form form={form} id="quiz-form">
                        {quizquestions.data?.data?.map((question, index) => {
                            return (
                                <div key={`question-title-${question.id}`} className="pb-3">
                                    <div className="mb-2 font-bold">{`(${index + 1}). ${question.question}`}</div>
                                    <Form.Item name={`${question.id}`} rules={[{ required: true, message: `Please attempt question ${index + 1}` }]} hasFeedback>
                                        <Radio.Group className="!flex flex-col">
                                            {Object.entries(question.options).map((option, index) => {
                                                return (
                                                    <Radio.Button key={`question-${question.id}-option-${index}`} value={option[0]} style={{ border: "0px" }} className={`group !bg-transparent before:!bg-transparent !my-0.5 !px-0 ${isAnswered ? 'pointer-events-none' : ''}`}>
                                                        {isAnswered ? userAnswer[question.id] == option[0] && question.answers.answers.includes(userAnswer[question.id])
                                                            ?
                                                            <div className={`group-[.ant-radio-button-wrapper-checked]:flex hidden w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center group-[.ant-radio-button-wrapper-checked]:bg-green-900/60`}>
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                    <CheckOutlined className="text-[7px]" />
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                            :
                                                            <div className={`group-[.ant-radio-button-wrapper-checked]:flex hidden w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center group-[.ant-radio-button-wrapper-checked]:bg-red-900/60`}>
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                    <CloseOutlined className="text-[7px]" />
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                            :
                                                            <div className={`group-[.ant-radio-button-wrapper-checked]:flex hidden w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center group-[.ant-radio-button-wrapper-checked]:bg-blue-900/60`}>
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                    <CheckOutlined className="text-[7px]" />
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                        }

                                                        {isAnswered ? userAnswer[question.id] != option[0] && !question.answers.answers.includes(userAnswer[question.id]) && question.answers.answers.includes(option[0])
                                                            ?
                                                            <div className="bg-green-900/60 group-[.ant-radio-button-wrapper-checked]:hidden flex w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center">
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                    <CheckOutlined className="text-[7px]" />
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                            :
                                                            <div className="group-[.ant-radio-button-wrapper-checked]:hidden flex w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center">
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                            :
                                                            <div className="group-[.ant-radio-button-wrapper-checked]:hidden flex w-full px-2 rounded border border-solid border-white/10 text-white h-full flex-row items-center">
                                                                <span className="rounded-full flex flex-col items-center justify-center w-3.5 h-3.5 mr-3 border border-solid border-white">
                                                                </span>
                                                                <span>{`${option[0]}. ${option[1]}`}</span>
                                                            </div>
                                                        }
                                                    </Radio.Button>
                                                )
                                            })}
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            )
                        })}



                    </Form>
                    <div className="flex flex-row justify-center">
                        {!isAnswered ? <Button className="w-36" onClick={() => {
                            form.validateFields()
                                .then((value) => {
                                    console.log(value);
                                    setIsAnswered(true)
                                    setUserAnswer(value)
                                    document.querySelector("main").scrollTo({
                                        behavior: 'smooth',
                                        top: 0,
                                        left: 0
                                    })

                                    console.log("test");

                                    var validAnswer = quizquestions.data?.data?.reduce((acc, ele) => {
                                        if (ele.answers.answers.includes(value[ele.id])) {
                                            acc.push(ele)
                                        }
                                        return acc
                                    }, []) 

                                    setCorrectAnswer(validAnswer)
                                    info({
                                        content:(<div className="font-bold text-lg">
                                            <span>{`Correct answers: ${validAnswer.length} out of ${quizquestions.data.data.length}`}</span>
                                        </div>),
                                        centered: true,
                                        icon: null
                                    })
                                    createQuizAnswer.mutate({quiz:id, answers:value})
                                })
                                .catch((err) => {
                                    form.scrollToField(err.errorFields?.[0]?.name?.[0], {
                                        behavior: 'smooth',
                                        block: 'center',
                                        inline: 'center',
                                    })

                                })
                        }} type="primary">Submit</Button> : <Button className="w-36" onClick={() => {
                            setIsAnswered(false)
                            setUserAnswer({})
                            form.resetFields()
                            document.querySelector("main").scrollTo({
                                behavior: 'smooth',
                                top: 0,
                                left: 0
                            })
                        }} type="primary">Take test again</Button>}
                    </div>
                </div>
            </>
        } />
    )
}

export default QuizPlay
