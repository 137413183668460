import { Space, Breadcrumb, Button, Table, Select, Tabs, Icon, Layout, Form, Input, DatePicker, Radio, Spin, message, Modal as AntModal, Card } from "antd"
import { DeleteOutlined, UserOutlined, ExclamationCircleFilled, EditOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Modal from "../Components/Modal";
import * as dayjs from 'dayjs'
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react";
import { httpClient } from "../Api/httpClient";
import { useMutation, useQuery } from "react-query";
import Container from "../Components/Container";

const { Content } = Layout
const { confirm } = AntModal
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const UserDetail = () => {
    const [userForm] = useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { id } = useParams()

    let navigate = useNavigate()


    const user = useQuery({ queryKey:[`users/${id}/`], queryFn: () => httpClient.get(`users/${id}/`) })

    const updateUser = useMutation({
        mutationFn: (data) => httpClient.patch(`users/${data.id}/`, data.data),
        onSuccess: () => {
            setIsModalOpen(false);
            user.refetch()
            userForm.resetFields()
        }
    })

    const deleteUser = useMutation({
        mutationFn: (id) => httpClient.delete(`users/${id}/`),
        onSuccess: () => {
            navigate("../")
        }
    })

    const tabItems = [
        {
            label: (
                <span>
                    <UserOutlined />
                    User information
                </span>
            ),
            key: 1,
            children: <div>
                <div className="flex flex-row mb-4 "><span className="font-bold w-24 block">Name </span><span>{user.data?.data?.first_name} {user.data?.data?.last_name}</span></div>
                <div className="flex flex-row mb-4"><span className="font-bold w-24 block">Gender </span><span>{user.data?.data?.gender}</span></div>
                <div className="flex flex-row mb-4"><span className="font-bold w-24 block">Birth date </span><span>{user.data?.data?.dob}</span></div>
                <div className="flex flex-row mb-4"><span className="font-bold w-24 block">Phone </span><span>{user.data?.data?.phone}</span></div>
                <div className="flex flex-row mb-4"><span className="font-bold w-24 block">Email </span><span>{user.data?.data?.email}</span></div>
                <div className="flex flex-row mb-4"><span className="font-bold w-24 block">Address </span><span>{user.data?.data?.address}</span></div>
            </div>,
        }
    ]


    const handleDelete = () => {
        confirm({
            title: 'Delete user',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this user?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            async onOk() {
                deleteUser.mutate(user.data.data.id)
            },
            onCancel() {
            },
        })
    }


    const handleEdit = () => {
        userForm.resetFields()
        userForm.setFieldsValue({
            email: user.data?.data?.email,
            first_name: user.data?.data?.first_name,
            last_name: user.data?.data?.last_name,
            phone: user.data?.data?.phone,
            dob: dayjs(user.data?.data?.dob, 'YYYY-MM-DD').isValid() ? dayjs(user.data?.data?.dob, 'YYYY-MM-DD') : "",
            address: user.data?.data?.address,
            gender: user.data?.data?.gender,
            id: user.data?.data?.id,
            usertype: user.data?.data?.usertype
        })
        userForm.validateFields()
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        userForm.resetFields()
    };

    const handleOk = () => {
        userForm.validateFields()
            .then(async (values) => {
                updateUser.mutate({id: user.data.data.id, data: { ...values, dob: values['dob'].format('YYYY-MM-DD'), password: "@Helloworld123" }})
            })
            .catch((err) => {

            })
    }
    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">User detail</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <Button type='text' className="!flex items-center justify-center" icon={<EditOutlined />} onClick={() => handleEdit()}>Edit</Button>
                    <Button type='text' className="!flex items-center justify-center" icon={<DeleteOutlined />} onClick={() => handleDelete()}>Delete</Button>
                    <Button type='text' className="!flex items-center justify-center" icon={<ArrowLeftOutlined />} onClick={() => navigate("../")}>Back</Button>
                </div>
            </>
        } body={
            <>
                <Tabs
                    defaultActiveKey="1"
                    items={tabItems}
                    className="!p-4 !pt-0"
                />
                <Modal
                    open={isModalOpen}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    confirmLoading={updateUser.isLoading}
                    centered
                    maskClosable={false}
                >
                    <Spin spinning={updateUser.isLoading} tip="Updating" size="large">
                        <Form form={userForm} name="user" layout="horizontal">
                            <FormItem name='first_name' rules={[{ required: true }]}
                                label={`Firstname`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter firstname' />
                            </FormItem>
                            <FormItem name='last_name' rules={[{ required: true }]}
                                label={`Lastname`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter lastname' />
                            </FormItem>
                            <FormItem name='gender' rules={[{ required: true }]}
                                label={`Gender`} hasFeedback {...formItemLayout}>
                                <Radio.Group>
                                    <Radio value="MALE">
                                        Male
                                    </Radio>
                                    <Radio value="FEMALE">
                                        Female
                                    </Radio>
                                </Radio.Group>
                            </FormItem>
                            <FormItem name='dob' label={`Birth date`} hasFeedback {...formItemLayout} rules={[{ required: true }]}>
                                <DatePicker format='MM/DD/YYYY' />
                            </FormItem>
                            <FormItem name='phone' rules={[{ required: true, message: `The input is not valid phone!`, }]}
                                label={`Phone`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter phone number' />
                            </FormItem>
                            <FormItem name='email' rules={[{ required: true, pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: `The input is not valid E-mail!`, }]}
                                label={`Email`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter email address' />
                            </FormItem>
                            <FormItem name='address' rules={[{ required: true, }]}
                                label={`Address`} hasFeedback {...formItemLayout}>
                                <Input placeholder='Enter address' />
                            </FormItem>
                            <FormItem name='usertype' valuePropName="value" rules={[{ required: true, }]}
                                label={`User type`} hasFeedback {...formItemLayout}>
                                <Select
                                    placeholder="Select user type"
                                    options={['STUDENT', 'TEACHER', 'ADMIN'].map((ins) => {
                                        return { label: ins.charAt(0).toUpperCase() + ins.substring(1).toLowerCase(), value: ins.toUpperCase() }
                                    })}
                                />
                            </FormItem>
                        </Form>
                    </Spin>
                </Modal>
            </>
        }
        />
    )
}

export default UserDetail;