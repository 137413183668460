import React, { useState } from "react";
import {
  Space,
  Button,
  Table,
  Form,
  Select,
  Input,
  DatePicker,
  Radio,
  Spin,
  Modal as AntModal,
} from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import Modal from "../Components/Modal";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import { httpClient } from "../Api/httpClient";
import { useNavigate } from "react-router-dom";
import Container from "../Components/Container";
import { useMutation, useQuery } from "react-query";

const FormItem = Form.Item;
const { confirm } = AntModal;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const Users = () => {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionMode, setActionMode] = useState("ADD");
  const [userToUpdate, setUserToUpdate] = useState({});

  const users = useQuery({
    queryKey: ["users/"],
    queryFn: () => httpClient.get("users/"),
  });

  const createUser = useMutation({
    mutationFn: (user) => httpClient.post("users/", user),
    onSuccess: () => {
      setIsModalOpen(false);
      users.refetch();
      userForm.resetFields();
    },
  });

  const updateUser = useMutation({
    mutationFn: (data) => httpClient.patch(`users/${data.id}/`, data.data),
    onSuccess: () => {
      setIsModalOpen(false);
      users.refetch();
      userForm.resetFields();
    },
  });

  const deleteUser = useMutation({
    mutationFn: (id) => httpClient.delete(`users/${id}/`),
    onSuccess: () => {
      users.refetch();
    },
  });

  const [userForm] = useForm();

  const columns = [
    {
      title: "Name",
      width: 150,
      dataIndex: "first_name",
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`${record.id}`);
          }, // click row
        };
      },
    },
    {
      title: "Email",
      width: 200,
      dataIndex: "email",
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`${record.id}`);
          }, // click row
        };
      },
    },
    {
      title: "Phone",
      width: 150,
      dataIndex: "phone",
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`${record.id}`);
          }, // click row
        };
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      width: 100,
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`${record.id}`);
          }, // click row
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 110,
      render: (_, record) => {
        return (
          <Space align="middle">
            <Button type="link" size="small" onClick={() => handleEdit(record)}>
              Edit
            </Button>
            <Button
              type="link"
              size="small"
              danger
              onClick={() => handleDelete(record)}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleEdit = (record) => {
    setActionMode("UPDATE");
    userForm.resetFields();
    setUserToUpdate(record);
    userForm.setFieldsValue({
      email: record.email,
      first_name: record.first_name,
      last_name: record.last_name,
      phone: record.phone,
      dob: dayjs(record.dob, "YYYY-MM-DD").isValid()
        ? dayjs(record.dob, "YYYY-MM-DD")
        : "",
      address: record.address,
      gender: record.gender,
      usertype: record.usertype,
    });
    console.log(record.usertype);
    setIsModalOpen(true);
    userForm.validateFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    userForm.resetFields();
  };

  const handleOk = () => {
    userForm
      .validateFields()
      .then(async (values) => {
        if (actionMode == "ADD") {
          createUser.mutate({
            ...values,
            dob: values["dob"].format("YYYY-MM-DD"),
            password: "hello@12345",
          });
        } else {
          if (userToUpdate?.id) {
            console.log(values);
            updateUser.mutate({
              id: userToUpdate.id,
              data: { ...values, dob: values["dob"].format("YYYY-MM-DD") },
            });
          }
        }
      })
      .catch(() => {});
  };

  const handleDelete = (record) => {
    confirm({
      title: "Delete user",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      async onOk() {
        deleteUser.mutate(record.id);
      },
      onCancel() {},
    });
  };

  return (
    <Container
      header={
        <>
          <div className="flex flex-col">
            <span className="text-2xl font-bold">Users</span>
          </div>
          <div className="flex flex-row justify-center items-center">
            <Button
              type="default"
              size="small"
              className="!flex items-center justify-center"
              icon={<PlusOutlined />}
              onClick={() => {
                setActionMode("ADD");
                setIsModalOpen(true);
              }}
            >
              Add
            </Button>
          </div>
        </>
      }
      body={
        <>
          <Table
            size="small"
            className="!p-4 !pt-0"
            loading={users.isLoading}
            rowSelection
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={users.data?.data}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 250 }}
          />

          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            onOk={handleOk}
            confirmLoading={createUser.isLoading || updateUser.isLoading}
            centered
            maskClosable={false}
          >
            <Spin
              spinning={createUser.isLoading || updateUser.isLoading}
              tip="Updating"
              size="large"
            >
              <Form form={userForm} name="user" layout="horizontal">
                <FormItem
                  name="first_name"
                  rules={[{ required: true }]}
                  label={`Firstname`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Input placeholder="Enter firstname" />
                </FormItem>
                <FormItem
                  name="last_name"
                  rules={[{ required: true }]}
                  label={`Lastname`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Input placeholder="Enter lastname" />
                </FormItem>
                <FormItem
                  name="gender"
                  rules={[{ required: true }]}
                  label={`Gender`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Radio.Group>
                    <Radio value="MALE">Male</Radio>
                    <Radio value="FEMALE">Female</Radio>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  name="dob"
                  label={`Birth date`}
                  hasFeedback
                  {...formItemLayout}
                  rules={[{ required: true }]}
                >
                  <DatePicker format="MM/DD/YYYY" />
                </FormItem>
                <FormItem
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: `The input is not valid phone!`,
                    },
                  ]}
                  label={`Phone`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Input placeholder="Enter phone number" />
                </FormItem>
                <FormItem
                  name="email"
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                      message: `The input is not valid E-mail!`,
                    },
                  ]}
                  label={`Email`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Input placeholder="Enter email address" />
                </FormItem>
                <FormItem
                  name="address"
                  rules={[{ required: true }]}
                  label={`Address`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Input placeholder="Enter address" />
                </FormItem>
                <FormItem
                  name="usertype"
                  rules={[{ required: true }]}
                  label={`User type`}
                  hasFeedback
                  {...formItemLayout}
                >
                  <Select
                    placeholder="Select user type"
                    options={["STUDENT", "TEACHER", "ADMIN"].map((ins) => {
                      return {
                        label:
                          ins.charAt(0).toUpperCase() +
                          ins.substring(1).toLowerCase(),
                        value: ins.toUpperCase(),
                      };
                    })}
                  />
                </FormItem>
              </Form>
            </Spin>
          </Modal>
        </>
      }
    />
  );
};

export default Users;
