import { Modal as Antmodal } from "antd"
import React, { useState } from "react"

const Modal = (props)=>{
    const [isOpen, setIsOpen] = useState(false)
    const  modalsettings = props
    return <Antmodal open={isOpen} { ...modalsettings }/>
}

export default Modal
