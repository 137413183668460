import { Button, Card, Tabs, Layout, List } from "antd";
import { useEffect, useState } from "react";
import { httpClient } from "../../Api/httpClient";
import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import dayjs from "dayjs";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";

const {Meta} = Card

const CourseStudent = () => {
    const { currentUser } = useAuth()

    let navigate = useNavigate()

    const courses = useQuery({ queryKey:["users/get_enrolled_course/"], queryFn: () => httpClient.get("users/get_enrolled_course/") })

    const enrolCourse = (id) => {
        console.log("enroll", id);
        // httpClient.post("users/enroll_course/", { course: id })
        //     .then((data) => {
        //         console.log(data);
        //     })
        //     .finally(() => {

        //     })

        var path = "https://uat.esewa.com.np/epay/main";
        var params = {
            amt: 100,
            psc: 0,
            pdc: 0,
            txAmt: 0,
            tAmt: 100,
            pid: id,
            scd: "EPAYTEST",
            su: "http://localhost:3000/course/",
            fu: "http://localhost:3000/course/"
        }

        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);

        for (var key in params) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
    }

    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">My Courses</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                </div>
            </>
        } body={
            <List
                className="overflow-y-auto overflow-x-hidden !p-4"
                grid={{
                    gutter: 20,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                loading={courses.isLoading}
                dataSource={courses.data?.data}
                renderItem={(item) => (
                    <List.Item>
                        <Card
                            onClick={() => {
                                if ((currentUser && currentUser.usertype == "STUDENT")) {
                                    navigate("" + item.id)
                                }
                            }}
                            hoverable
                            cover={
                                <img
                                    alt="example"
                                    src="https://cdn.mos.cms.futurecdn.net/HFUAjfbamNhbM8dsNSQW3D.jpg"
                                />
                            }
                        >
                            <Meta
                                title={item.name}
                                description={
                                    <>
                                        <span className="text-xs text-white/50">{"Starting from " + dayjs(item.startdate).format('ddd, MMM D, YYYY')}</span>
                                    </>
                                }
                            />
                        </Card>
                    </List.Item>
                )}
            />
        } />
    )
}

export default CourseStudent