import React, { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { httpClient } from "../Api/httpClient";

const cookie = new Cookies()

const AuthContext = createContext();


const setUser = (data) => {
  if (data?.user) {
    localStorage.setItem("user", JSON.stringify(data.user))
  }
}

const User = () => {
  return JSON.parse(localStorage.getItem("user"))
}

const setAccessToken = (data) => {
  if (data?.access) {
    localStorage.setItem("access", JSON.stringify(data.access))
  }
}

const setRefreshToken = (data) => {
  if (data?.refresh) {
    localStorage.setItem("refresh", JSON.stringify(data.refresh))
  }
}

const AccessToken = () => {
  return JSON.parse(localStorage.getItem("access"))
}

const RefreshToken = () => {
  return JSON.parse(localStorage.getItem("refresh"))
}
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(User())
  const navigate = useNavigate();

  // call this function when you want to authenticate the user

  const login = (data) => {
    setUser(data)
    setCurrentUser(User())

  }

  const logout = () => {
    httpClient.get('logout/', {})
      .then((res) => {
        setCurrentUser(null)
        localStorage.removeItem("user")
        cookie.remove('sessionid')
        cookie.remove('csrftoken')
        navigate("/login", { replace: true })
      })
  }

  return <AuthContext.Provider value={{ User, login, logout, currentUser, AccessToken, RefreshToken, setAccessToken, setRefreshToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};