import { Routes, Route, Navigate } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import "./App.css";
import LoginPage from "./Pages/Login/Login";
import HomePage from "./Pages/Home/Home";
import routes from "./utils/routes.config";
import { useAuth } from "./hooks/useAuth";
import Meet from "./Pages/Meet/Meet";
import { MessageProvider } from "./hooks/useMessage";

const App = () => {
  const { currentUser } = useAuth();
  return (
    <>
      <StyleProvider hashPriority="high">
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
        >
          <MessageProvider>
            <Routes>
              <Route path="/" element={<HomePage />}>
                {routes({ user: currentUser }).map((route, index) => {
                  if (!route.allowedRoles.includes(currentUser?.usertype))
                    return;
                  return (
                    <Route
                      index={route.index}
                      path={route.path}
                      element={route.element}
                      key={index}
                    >
                      {route?.children?.map((childRoute, childIndex) => {
                        return (
                          <Route
                            index={childRoute.index}
                            path={childRoute.path}
                            element={childRoute.element}
                            key={index + "" + childIndex}
                          />
                        );
                      })}
                    </Route>
                  );
                })}
              </Route>
              <Route path="login" element={<LoginPage />} />
              <Route path="meet" element={<Meet />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </MessageProvider>
        </ConfigProvider>
      </StyleProvider>
    </>
  );
};

export default App;
