import React, { useState, useEffect } from "react";
import { Button, Checkbox, Card, DatePicker, Form, Input, List, Radio, Select, Spin, Switch, Modal as AntModal, Table } from "antd"
import { httpClient, ROOT_URL } from "../../Api/httpClient";
import { PlusOutlined, CloseOutlined, EditFilled, ExclamationCircleFilled, DeleteFilled, ArrowLeftOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Container";
import { useMutation, useQuery } from "react-query";
import Modal from "../../Components/Modal";
import { useForm } from "antd/es/form/Form";
import { CirclePicker } from "react-color";

const { confirm, error } = AntModal;

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const Quiz = () => {

    const navigate = useNavigate()
    const [form] = useForm();

    const { id } = useParams()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [actionMode, setActionMode] = useState("ADD")
    const [updateData, setUpdateData] = useState(null)
    const [options, setOptions] = useState(["", "", "", ""])

    const quizquestions = useQuery({ queryKey: [`quizquestions/by_quiz/${id}/`], queryFn: () => httpClient.get(`quizquestions/by_quiz/${id}/`) })
    const quiz = useQuery({ queryKey: [`quizzes/${id}/`], queryFn: () => httpClient.get(`quizzes/${id}/`) })

    const createQuizQuestion = useMutation({
        mutationFn: (data) => httpClient.post("quizquestions/", data),
        onSuccess: () => {
            setIsModalOpen(false);
            quizquestions.refetch()
            form.resetFields()
        }
    })

    const updateQuizQuestion = useMutation({
        mutationFn: (data) => httpClient.patch(`quizquestions/${data.id}/`, data.values),
        onSuccess: () => {
            setIsModalOpen(false);
            quizquestions.refetch()
            form.resetFields()
        }
    })

    const deleteQuizQuestion = useMutation({
        mutationFn: (id) => httpClient.delete(`quizquestions/${id}/`),
        onSuccess: () => {
            quizquestions.refetch()
        }
    })


    const columns = [
        {
            title: 'Question',
            // width: 150,
            dataIndex: 'question',
            render: (question, record) => {
                return <div>
                    <div className="flex flex-row items-center justify-between">
                        <span className="font-bold">{question}</span>
                        <div className="flex flex-row items-center">
                            <Button type="text" icon={<EditFilled className="text-xs" />} size="small" onClick={() => { handleEdit(record) }} />
                            <Button type="text" icon={<DeleteFilled className="text-xs" />} size="small" onClick={() => {handleDelete(record)}}/>
                        </div>
                    </div>
                </div>
            }
        }
    ];

    const handleEdit = (record) => {
        setActionMode('UPDATE')
        setUpdateData(record)
        setOptions(Object.values(record.options))
        form.resetFields()

        let answers = {}

        if (record?.answers?.answers) {
            answers = record.answers.answers.reduce((acc, el) => {
                acc[el] = true
                return acc
            }, {})

        }

        console.log(record.options);
        form.setFieldsValue({
            question: record.question,
            answers: answers,
            options: record.options,
        })
        form.validateFields()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                let answers = Object.entries(values.answers).reduce((acc, ele) => {
                    const [key, value] = ele
                    if (value) {
                        acc.push(key)
                    }
                    return acc
                }, [])
                if (answers.length) {
                    if (actionMode == "ADD") {
                        createQuizQuestion.mutate({ quiz: [id], ...values, answers: { answers: answers } })
                    } else {
                        updateQuizQuestion.mutate({ id: updateData.id, values: { quiz: [id], ...values, answers: { answers: answers } } })
                    }
                } else {
                    error({
                        content: "Please check correct answers."
                    })
                }


            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }



    const handleDelete = (record) => {
        confirm({
            title: 'Delete class',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this class?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteQuizQuestion.mutate(record.id)
            },
            onCancel() {
            },
        })
    }

    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">{`Quiz${quiz.data?.data?.name ? ' - ' + quiz.data?.data?.name : ""}`}</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <Button type='default' size="small" className="!flex items-center justify-center" icon={<PlusOutlined />} onClick={() => {
                        setActionMode("ADD");
                        setOptions(["", "", "", ""])
                        form.resetFields();
                        setIsModalOpen(true);
                    }} >Add</Button>
                    <Button type='default' size="small" className="!flex items-center justify-center ml-1" icon={<ArrowLeftOutlined />} onClick={() => { navigate("../") }}>Back</Button>
                </div>
            </>
        } body={
            <>
                <div className="flex flex-col h-full !p-4 !pt-3">
                    <div className="pl-5 h-14 font-bold bg-[#1d1d1d] flex flex-row items-center" style={{borderBottom:"1px solid #303030"}}><span>Total questions - {quizquestions.data?.data?.length}</span></div>
                    <Table
                        className="quiz-table-wrapper"
                        loading={quizquestions.isLoading}
                        rowKey={(record) => record.id}
                        columns={columns}
                        // footer={() => 'Footer'}
                        showHeader={false}
                        // pagination={false}
                        dataSource={quizquestions.data?.data}
                        pagination={false}
                        scroll={{ y: 250 }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="ml-[57px]">{Object.entries(record.options).map((ele, index) => {
                                    const [key, value] = ele
                                    let isChecked = false;
                                    if (record?.answers?.answers) {
                                        isChecked = record.answers.answers.includes(key)
                                    }
                                    return <div className="py-1" key={`quizquestiondetail-${index}`}>
                                        <Checkbox className="!mr-3 pointer-events-none" checked={isChecked} />
                                        <span className="mr-4 font-bold">{key}.</span>
                                        <span>{value}</span>
                                    </div>
                                })}</div>
                            ),
                            rowExpandable: (record) => true,
                        }}
                    />
                </div>
                <Modal
                    open={isModalOpen}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    confirmLoading={createQuizQuestion.isLoading || updateQuizQuestion.isLoading}
                    centered
                    title={actionMode == "ADD" ? "Add question" : "Update question"}
                    maskClosable={false}
                >
                    <Spin spinning={createQuizQuestion.isLoading || updateQuizQuestion.isLoading} tip="Processing" size="large">
                        <Form form={form} name="quizquestion" layout="vertical" initialValues={{ color: "#039be5", active: true }}>
                            <FormItem name='question' rules={[{ required: true }]}
                                label={`Question`} hasFeedback className="w-full">
                                <Input size="small" placeholder='Enter question' className="w-full" />
                            </FormItem>
                            <FormItem label="Options">
                                {options.map((option, index) => {
                                    return (
                                        <div className="flex flex-row items-center justify-center" key={`question-${index}`}>
                                            <span className="mr-2 mb-[24px]">{String.fromCharCode(65 + index)}.</span>
                                            <FormItem name={['options', String.fromCharCode(65 + index)]} rules={[{ required: true, message: 'Options is required' }]}
                                                hasFeedback className="w-full inline-block" key={index}>
                                                <Input size="small" placeholder='Enter option' className="w-full" />
                                            </FormItem>
                                            <FormItem name={['answers', String.fromCharCode(65 + index)]} valuePropName="checked" className="inline-block !ml-2">
                                                <Checkbox />
                                            </FormItem>
                                            {options.length > 2 && <Button key={`question-remove-button-${index}`} type="text" size="small" icon={<CloseOutlined className="!text-xs" />} className="mb-[24px] ml-2" onClick={() => {
                                                let tempOptions = [...options]
                                                tempOptions.splice(index, 1)
                                                console.log(tempOptions);
                                                setOptions(tempOptions)
                                            }} />}
                                        </div>
                                    )
                                })}
                            </FormItem>
                            <FormItem>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                                    setOptions([...options, ""])

                                }}>Add option</Button>
                            </FormItem>
                        </Form>
                    </Spin>
                </Modal>
            </>
        } />
    )
}

export default Quiz
