import axios from "axios";
import { Modal, message } from "antd";
import Cookies from "universal-cookie";

export const ROOT_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:8000/" : "/api";

const cookie = new Cookies();

export const httpClient = axios.create({
  baseURL: ROOT_URL,
  withCredentials: true,
});

httpClient.interceptors.request.use(
  function (request) {
    request.headers.set("X-CSRFToken", cookie.get("csrftoken"));
    return request;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  function (response) {
    // console.log(response);
    let successMessage = "";
    if (response.status != 200) {
      message.success(successMessage);
    }
    return response;
  },
  async function (error) {
    let errorMessage = "Something went wrong. Please contact administrator";
    switch (error?.response?.status) {
      case 400:
        break;

      case 403:
        errorMessage = "Session has expired. Please login again.";
        break;
      default:
        break;
    }

    Modal.error({
      content: errorMessage,
      centered: true,
      maskClosable: false,
    });
    return Promise.reject(error);
  },
);
