import { createContext, useContext, useState } from "react";
import chat from "../utils/Chat";

const MessageContext = createContext();

export const MessageProvider = (props) => {
  const [messages, setMessages] = useState([]);

  const connect = () => {
    chat.connect();
    chat.getInstance().onmessage = (message) => {
      setMessages(message);
    };
  };

  const disconnect = () => {
    chat.disconnect();
  };

  return (
    <MessageContext.Provider
      value={{ connect: connect, disconnect: disconnect, messages: messages }}
    >
      {props.children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  return useContext(MessageContext);
};
