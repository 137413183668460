import { Button, Form, Input, Layout } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import React from "react";
import { httpClient } from "../../Api/httpClient";
import { useAuth } from "../../hooks/useAuth";
import { useMutation } from "react-query";

const LoginPage = () => {
  const { login, currentUser } = useAuth();

  const loginMutation = useMutation({
    mutationFn: (user) => {
      return httpClient.post("login/", user);
    },
    onSuccess: (data) => {
      login({ user: data.data });
    },
  });

  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  const onFinish = (values) => {
    loginMutation.mutate({ username: values.email, password: values.password });
  };

  return (
    <Layout
      style={{ height: "100%" }}
      className="flex flex-col items-center justify-center"
    >
      <div className="text-white text-4xl mb-4">
        <span>Welcome</span>
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your Email!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loginMutation.isLoading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default LoginPage;
