import React, { useEffect } from "react"


const Meet = () => {


    return <div className="h-full" data-lk-theme="default">

    </div>
}

export default Meet
