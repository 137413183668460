import React, { useState, useEffect } from "react";
import { Card, List} from "antd"
import { httpClient, ROOT_URL } from "../../Api/httpClient";

import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import { useQuery } from "react-query";

const Library = () => {

    const navigate = useNavigate()

    const library = useQuery({ queryKey:["library/"], queryFn: () => httpClient.get("library/") })

    return (
        <Container header={
            <>
                <div className="flex flex-col">
                    <span className="text-2xl font-bold">Library</span>
                </div>
                <div className="flex flex-row justify-center items-center">
                </div>
            </>
        } body={
            <>
                <List
                    className="!p-4 "
                    grid={{
                        gutter: 20,
                        xs: 1,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    loading={library.isLoading}
                    dataSource={library.data?.data}
                    renderItem={(item) => (
                        <List.Item>
                            <Card hoverable
                                cover={
                                    <div style={{background:item.color}} className="rounded-t p-2 !flex items-center justify-center">
                                        <img src={`${ROOT_URL}/static${item.avatar}`} height={96} width={96}/>
                                    </div>
                                }
                                onClick={() => { navigate("" + item.id) }}
                            >
                                <div className="font-bold text-center text-md">{item.name}</div>
                            </Card>
                        </List.Item>
                    )}
                />
            </>
        } />
    )
}

export default Library
