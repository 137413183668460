import { Outlet } from "react-router-dom";


const UserOutlet = () => {

    return <>
        <Outlet />
    </>
}

export default UserOutlet

