import React, { useState, useEffect, useRef } from "react";
import { Space, Upload, Button, Table, Layout, Form, Input, Spin, message, Modal as AntModal } from "antd"
import { PlusOutlined, PlayCircleFilled, ExclamationCircleFilled, ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import Modal from "../../Components/Modal";
import { useForm } from "antd/es/form/Form";
import { httpClient, ROOT_URL } from "../../Api/httpClient";

import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Container";
import { useMutation, useQuery } from "react-query";
import { BigPlayButton, LoadingSpinner, Player } from "video-react";

const FormItem = Form.Item;
const { confirm } = AntModal;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
}

const Note = () => {

    let navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)

    const [form] = useForm();

    const { id } = useParams()

    const [isVideoModelOpen, setVideoModelOpen] = useState(false)
    const [uploadFile, setUploadFile] = useState([])
    const [currentLecture, setCurrentLecture] = useState({})
    const [thumbnail, setThumbnail] = useState([])

    const playerRef = useRef()

    const notes = useQuery({ queryKey: [`notes/notes_by_library/${id}/`], queryFn: () => httpClient.get(`notes/notes_by_library/${id}/`) })
    const library = useQuery({ queryKey: [`library/${id}/`], queryFn: () => httpClient.get(`library/${id}/`) })


    const createNote = useMutation({
        mutationFn: (data) => httpClient.post("notes/", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }),
        onSuccess: () => {
            setIsModalOpen(false);
            notes.refetch()
            library.refetch()
            form.resetFields()
            setUploadFile([])
            setThumbnail([])
        }
    })


    const deleteNote = useMutation({
        mutationFn: (id) => httpClient.delete(`notes/${id}/`),
        onSuccess: () => {
            notes.refetch()
            library.refetch()
        }
    })

    const downloadFile = (record) => {
        const el = document.createElement("a");
        el.setAttribute("href", `${ROOT_URL}notes/lecture/${record.id}/`);
        el.setAttribute(
            "download",
            `${ROOT_URL}notes/lecture/${record.id}/`
        );
        el.click();
    }

    const columns = [
        {
            title: 'Note',
            width: 150,
            dataIndex: 'name',
            render: (classname, record) => {
                return <>
                    {
                        library.data?.data.type == "video" ? <div className="flex items-center cursor-pointer" onClick={() => {
                            setCurrentLecture(record)
                            setVideoModelOpen(true)
                            playerRef.current?.load()
                        }}>
                            <div className="flex items-center justify-center relative">
                                <img src={`${ROOT_URL}notes/thumbnail/${record.id}/`} className="h-16 mr-2 rounded" />
                                <PlayCircleFilled className="absolute" style={{ fontSize: '24px' }} />
                            </div>
                            <a>{classname}</a>
                        </div> : <a>{classname}</a>
                    }
                </>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 80,
            render: (_, record) => {
                return <Space align='middle'>
                    <Button type="link" size="small" onClick={() => downloadFile(record)}>
                        Download
                    </Button>
                    <Button type="link" size="small" danger onClick={() => handleDelete(record)}>
                        Delete
                    </Button>
                </Space>
            }
        }
    ];

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                let formData = new FormData();
                formData.append('name', values.name);
                formData.append('library', library.data?.data.id);
                formData.append('file', values.file.originFileObj, values.file.originFileObj.name);
                if (library.data?.data.type == "video")
                    formData.append('thumbnail', values.thumbnail.originFileObj, values.thumbnail.originFileObj.name);
                console.log(values);
                createNote.mutate(formData)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDelete = (record) => {
        confirm({
            title: 'Delete class',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this class?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            async onOk() {
                deleteNote.mutate(record.id)
            },
            onCancel() {
            },
        })
    }


    const closeVideo = () => {
        setVideoModelOpen(false)
        playerRef.current?.pause()
    }



    const props = {
        onRemove: (file) => {
            setUploadFile([])
        },
        beforeUpload: (file) => {
            setUploadFile([file])
            form.setFieldValue("name", file.name.substring(0, file.name.lastIndexOf(".")))
            return false;
        },
    };

    const propsThumbnail = {
        onRemove: (file) => {
            setThumbnail([])
        },
        beforeUpload: (file) => {
            console.log(file);
            setThumbnail([file])
            return false;
        },
    };

    const normFile = (e) => {
        return e?.fileList?.[0]
    }

    return (
        <Container
            header={
                <>
                    <div className="flex flex-col">
                        <span className="text-2xl font-bold">{library.data?.data ? library.data.data.name : "Notes"}</span>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <Button type='text' className="!flex items-center justify-center" icon={<PlusOutlined />} disabled={library.isError || library.isLoading} onClick={() => { setIsModalOpen(true); }} >Add</Button>
                        <Button type='text' className="!flex items-center justify-center" icon={<ArrowLeftOutlined />} onClick={() => { navigate("../") }}>Back</Button>
                    </div>
                </>
            }
            body={
                <>
                    <Table
                        className="!p-4 !pt-0"
                        loading={notes.isLoading}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={notes.data?.data}
                        pagination={{ pageSize: 50 }}
                        scroll={{ y: 250 }} />
                    <Modal
                        open={isModalOpen}
                        onCancel={handleCancel}
                        onOk={handleOk}
                        confirmLoading={createNote.isLoading}
                        centered
                        maskClosable={false}

                    >
                        <Spin spinning={createNote.isLoading} tip="Updating" size="large">
                            <Form form={form} name="user" layout="horizontal">
                                <FormItem name='file' rules={[{ required: true }]}
                                    label={`Note`} hasFeedback {...formItemLayout} getValueFromEvent={normFile}>
                                    <Upload accept={library.data?.data.type == "video" ? "video/*" : "*/*"} multiple={false} fileList={uploadFile} {...props} listType="picture">
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                </FormItem>
                                {library.data?.data.type == "video" && <FormItem name='thumbnail' rules={[{ required: true }]}
                                    label={`Thumbnail`} hasFeedback {...formItemLayout} getValueFromEvent={normFile}>
                                    <Upload accept="image/*" multiple={false} fileList={thumbnail} {...propsThumbnail} listType="picture">
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                </FormItem>}
                                <FormItem name='name' rules={[{ required: true }]}
                                    label={`Note name`} hasFeedback {...formItemLayout}>
                                    <Input placeholder='Enter name' />
                                </FormItem>
                            </Form>
                        </Spin>
                    </Modal>

                    <Modal
                        open={isVideoModelOpen}
                        onCancel={closeVideo}
                        centered
                        maskClosable={false}
                        footer={null}
                        title={currentLecture.name}
                        maskStyle={{ backdropFilter: "blur(5px)", backgroundColor: "rgba(0,0,0,0.25)" }}
                        className="video-modal"
                        destroyOnClose={true}
                    >
                        <div onContextMenu={(e) => { e.preventDefault() }}>
                            <Player ref={playerRef} autoPlay poster={`${ROOT_URL}notes/thumbnail/${currentLecture.id}/`} >
                                <LoadingSpinner />
                                <BigPlayButton position="center" />
                                <source src={`${ROOT_URL}notes/lecture/${currentLecture.id}/`} accessKey="hello" />
                            </Player >
                        </div>
                    </Modal>
                </>
            }
        />
    )
}

export default Note
