import React from "react";
import { Outlet } from "react-router-dom";

const LibraryOutlet = () => {

    return <>
        <Outlet />
    </>
}

export default LibraryOutlet

