import { Outlet } from "react-router-dom";


const QuizOutlet = () => {

    return <>
        <Outlet />
    </>
}

export default QuizOutlet

